import React from 'react';
import before_single from './img/triggers/before.png';
import before_loop from './img/triggers/before-loop.png';
import after_single from './img/triggers/after.png';
import after_loop from './img/triggers/after-loop.png';
import repeat_5 from './img/triggers/trigger-5-times.png';
import repeat_10 from './img/triggers/trigger-10-times.png';
import repeat_25 from './img/triggers/trigger-25-times.png';
import repeat_50 from './img/triggers/trigger-50-times.png';
import repeat_a from './img/triggers/trigger-a-times.png';
import repeat_b from './img/triggers/trigger-b-times.png';
import repeat_c from './img/triggers/trigger-c-times.png';
import repeat_d from './img/triggers/trigger-d-times.png';

const arrayTrigger = ["", before_single, before_loop, after_loop, after_single, repeat_5, repeat_10, repeat_25, repeat_50, repeat_a, repeat_b, repeat_c, repeat_d];

function TriggerAdditional(props) {

    let borderClass;
    if(props.value === 0) {
        borderClass = "trigger--is-empty";
    } else {
        borderClass = "trigger--not-empty"
    }
    let triggerButtonStyle = {
        backgroundImage:"url(" + arrayTrigger[props.value] + ")",
        backgroundSize: "contain"
    }

    return(
        <div className={props.className}>
            <button 
            style = {triggerButtonStyle}
            className={borderClass + " cell--inner cell--trigger"} 
            onClick = {props.onClick}
            />
        </div>
    );
}

export default TriggerAdditional;