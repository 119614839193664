import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Cell from "./Cell.js";
import Trigger from "./Trigger.js";
import Special from "./Special.js";
import TriggerModifier from "./TriggerModifier.js";
import TriggerAdditional from "./TriggerAdditional.js";
import Arrow from "./Arrow.js";

import trigger8 from './img/triggers/1-2-random.png';
import trigger9 from './img/triggers/2-2-random.png';
import trigger16 from './img/triggers/1-4-random.png';
import trigger17 from './img/triggers/2-4-random.png';
import trigger18 from './img/triggers/3-4-random.png';
import trigger19 from './img/triggers/4-4-random.png';
import trigger_star from './img/triggers/trigger-star.png'; 

import random_1_2_i from './img/triggers/onetwo.png';
import random_2_2_i from './img/triggers/twotwo.png';
import random_1_4_i from './img/triggers/onefour.png';
import random_2_4_i from './img/triggers/twofour.png';
import random_3_4_i from './img/triggers/threefour.png';
import random_4_4_i from './img/triggers/fourfour.png';

import icon_before from './img/triggers/before.png';
import icon_before_loop from './img/triggers/before-loop.png';
import icon_after from './img/triggers/after.png';
import icon_after_loop from './img/triggers/after-loop.png';
import repeat_5 from './img/triggers/trigger-5-times.png';
import repeat_10 from './img/triggers/trigger-10-times.png';
import repeat_25 from './img/triggers/trigger-25-times.png';
import repeat_50 from './img/triggers/trigger-50-times.png';
import repeat_a from './img/triggers/trigger-a-times.png';
import repeat_b from './img/triggers/trigger-b-times.png';
import repeat_c from './img/triggers/trigger-c-times.png';
import repeat_d from './img/triggers/trigger-d-times.png';
import trigger_empty from './img/triggers/trigger-empty.png';
import RuleMenu from './RuleMenu.js';

/*
Rule indexes

0 - Trigger Main
1 - Trigger Additional
2 - Cell 1
3 - Cell 2 
4 - Cell 3
5 - Cell 4 
6 - Cell 5
7 - Cell Output
8 - Special Output Main
9 - Special Output Secondary - Reserved for future use (eg sound)
10 - Arrow
11 - Conditional
*/
const TRIGGER1 = 0;
const TRIGGER2 = 1;
const TRIGGER3 = 11; //?move to 2 and rejig everything
const CELL1 = 2;
const CELL2 = 3;
const CELL3 = 4;
const CELL4 = 5;
const CELL5 = 6;
const OUTPUT = 7;
const SPECIAL1 = 8;
//const SPECIAL2 = 9;
const ARROW = 10;
//const images = require.context('../public/img', true);

class Rule extends React.Component {
  
  renderCell(i, cell, rule, className) {
    const spriteCellStyle = {
      width: 32, // this.props.cellWidth
      height: 32, //this.props.cellHeight
      transform: 1,
      transformOrigin: "top left"
      // Original transform: "scale(" + 32 / this.props.spriteWidth + ")"
    }
    //console.log("&&&", this.props.cellWidth, this.props.cellHeight);
      
    return(
      <Cell
        cellIndex = {className}
        value = {i}
        className = {className}
        style = {spriteCellStyle} 
        spriteSheet = {this.props.spriteSheet} 
        spriteWidth = {this.props.spriteWidth}
        spriteHeight = {this.props.spriteHeight} 
        spritesPerRow = {this.props.spritesPerRow}
        imgsrc = {this.props.imgsrc}
        transform = {"scale(" + (32 / Math.max(this.props.spriteWidth, this.props.spriteHeight)) + ")"}
        renderStyle = "crisp-edges"
        onClick={ () => this.props.onClick(cell, rule) }
        onContextMenu={ (e) => this.props.onContextMenu(e, cell, rule) }
        position = "relative"
      />
    );
  }

  renderTrigger(i) {        
    return( 
      <Trigger 
        key={ "trigger_" + String(this.props.index) + "_" + String(this.props.triggers[i].index) }
        onClick = { () => this.props.onClick(TRIGGER1, this.props.index, this.props.triggers[i].index) } 
        value = { String(this.props.triggers[i].index) } 
        className = { this.props.triggers[i].style }        
        image = { this.props.triggers[i].image }
        text = { this.props.triggers[i].text }        
      />
    );
  }

  renderSpecial(i, cell, rule) {    
    return(
      <Special
        key={i}
        value = {this.props.specialsConfig[i].index} 
        className = "special__wrapper"
        style = {this.props.specialsConfig[i].style}
        label = {this.props.specialsConfig[i].label}
        onClick = { () => this.props.onClick(cell, rule, this.props.specialsConfig[i].index) }
      />   
    );
  }
  
  render() {
    let iconStyle = {
      /*border: "1px solid gray",*/
      width: 32,
      height: 32,
      textAlign:"center",
      background:"#fff",
      margin: "4px",
      float: "left",
      padding: "0",
      border: "none"
    }
    let popoverStyle = {
      paddingBottom: "7px"
    }
    let imgStyle = {
      width: 32,    
      height: 32
    }
    let triggers = [];

    for(let i = 0; i < (this.props.triggers.length - 1); i++) {
      triggers.push(this.renderTrigger(i));
    }

    const conditionalPopover = (
      <Popover style={popoverStyle} id="trigger-popover" title="Condition">
        <div >
          <button key="conditional1" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 1)} style={imgStyle} src={trigger8} /></button>
          <button key="conditional2" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 2)} style={imgStyle} src={trigger9} /></button>
          <button key="conditional3" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 3)} style={imgStyle} src={trigger16} /></button>
          <button key="conditional4" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 4)} style={imgStyle} src={trigger17} /></button>
          <button key="conditional5" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 5)} style={imgStyle} src={trigger18} /></button>
          <button key="conditional6" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 6)} style={imgStyle} src={trigger19} /></button>
          <button key="conditional7" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 7)} style={imgStyle} src={random_1_2_i} /></button>
          <button key="conditional8" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 8)} style={imgStyle} src={random_2_2_i} /></button>
          <button key="conditional9" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 9)} style={imgStyle} src={random_1_4_i} /></button>
          <button key="conditional10" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 10)} style={imgStyle} src={random_2_4_i} /></button>
          <button key="conditional11" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 11)} style={imgStyle} src={random_3_4_i} /></button>
          <button key="conditional12" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 12)} style={imgStyle} src={random_4_4_i} /></button>
          <button key="conditional13" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 13)} style={imgStyle} src={trigger_star} /></button>          
          <button key="conditional14" style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER2, this.props.index, 0)} style={imgStyle} src={trigger_empty} /></button>        
        </div>
      </Popover>
    );
    const triggerPopover = (
      <Popover style={popoverStyle} id="trigger-popover" title="Trigger">
        <div >          
          { triggers }
          <Trigger onClick={() => this.props.onClick(TRIGGER1, this.props.index, 0)} value="empty" className="popover__icon" />
        </div>
      </Popover>
    );
    const additionalPopover = (
      <Popover style={popoverStyle} id="additional-popover" title="Additional">
        <div >        
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 1)} style={imgStyle} src={icon_before} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 2)} style={imgStyle} src={icon_before_loop} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 3)} style={imgStyle} src={icon_after_loop} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 4)} style={imgStyle} src={icon_after} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 5)} style={imgStyle} src={repeat_5} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 6)} style={imgStyle} src={repeat_10} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 7)} style={imgStyle} src={repeat_25} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 8)} style={imgStyle} src={repeat_50} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 9)} style={imgStyle} src={repeat_a} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 10)} style={imgStyle} src={repeat_b} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 11)} style={imgStyle} src={repeat_c} /></button>
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 12)} style={imgStyle} src={repeat_d} /></button>          
          <button style={iconStyle}><img alt="trigger" onClick={() => this.props.onClick(TRIGGER3, this.props.index, 0)} style={imgStyle} src={trigger_empty} /></button>
        </div>
      </Popover>
    );      

    let specialsPopoverStyle = {
      paddingBottom: "7px",
      maxWidth: "228px",
      paddingLeft: "2px"
    }

    let specials = [];    
    for(let i = 0; i < (this.props.specialsConfig.length - 1); i++) {
      specials.push(this.renderSpecial(i, SPECIAL1, this.props.index));
    }    

    const specialPopover = (
      <Popover style={specialsPopoverStyle} id="special-popover" title="Special">
        { specials }
      </Popover>
    );

    let classes = "rule " + this.props.className + " ";
    let ruleLabel = String(this.props.ruleNo) + ".";
    if((this.props.rule[11] === 2 || this.props.rule[11] === 3) && this.props.rule[4] !== 0 && (this.props.rule[4] === this.props.rule[7])) {
     classes += "rule--error";
     ruleLabel += " ERROR - Infinite loop";     
    }

    return (
      <div className={classes}>                    
          <RuleMenu
            ruleNo={this.props.ruleNo}            
            ruleLabel={ruleLabel}
            onClick={ (e) => this.props.onClick(e, this.props.index)}
          />            
          <div className="rule__inner">
            <OverlayTrigger trigger="click" overlay={additionalPopover} placement="right" rootClose>
              <TriggerAdditional
                cellIndex={TRIGGER3}
                value={this.props.rule[TRIGGER3]}
                className={"cell-trigger3"}
                ref={this.attachRef}
              />
            </OverlayTrigger>
            <OverlayTrigger trigger="click" overlay={triggerPopover} placement="right" rootClose>
              <Trigger
                cellIndex={TRIGGER1}
                value={this.props.rule[TRIGGER1]}
                className={"cell-trigger1"}
                ref={this.attachRef}
                image = {this.props.triggersIndex[this.props.rule[TRIGGER1]].image}
                text = {this.props.triggersIndex[this.props.rule[TRIGGER1]].text}
              />
            </OverlayTrigger>
            <OverlayTrigger trigger="click" overlay={conditionalPopover} placement="right" rootClose>
              <TriggerModifier
                cellIndex={TRIGGER2}
                value={this.props.rule[TRIGGER2]}
                className={"cell-trigger2"}
                ref={this.attachRef}
              />
            </OverlayTrigger>
            {this.renderCell(this.props.rule[CELL1], CELL1, this.props.index, "cell1")}
            {this.renderCell(this.props.rule[CELL2], CELL2, this.props.index, "cell2")}
            {this.renderCell(this.props.rule[CELL3], CELL3, this.props.index, "cell3")}
            {this.renderCell(this.props.rule[CELL4], CELL4, this.props.index, "cell4")}
            {this.renderCell(this.props.rule[CELL5], CELL5, this.props.index, "cell5")}
            {this.renderCell(this.props.rule[OUTPUT], OUTPUT, this.props.index, "cell-output")}
            <Arrow
              value={this.props.rule[ARROW]}
              className={"arrow-wrapper"}
              onClick={ () => this.props.onClick(ARROW, this.props.index)}
            />
            <OverlayTrigger trigger="click" overlay={specialPopover} placement="right" rootClose>
              <Special            
                key={this.props.index}
                value = {this.props.rule[SPECIAL1]} 
                className= "special__wrapper_onrule"
                style = {this.props.specialsConfigIndex[this.props.rule[SPECIAL1]].style}        
                label = {this.props.specialsConfigIndex[this.props.rule[SPECIAL1]].label}        
                onClick = { () => this.props.onClick(SPECIAL1, this.props.index, this.props.rule[SPECIAL1]) }
              />
            </OverlayTrigger>
          </div>   
      </div>
    );
  }
}

export default Rule;