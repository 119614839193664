import React from 'react';
import trigger_empty from './img/triggers/trigger-empty.png';

function Trigger(props) {    
    let borderClass, triggerImage;
    if(props.value === 0) {
        borderClass = "trigger--is-empty";
    } else {
        borderClass = "trigger--not-empty"
    }
    
    if(props.value === "empty"){
        triggerImage = trigger_empty;
    } else {
        triggerImage = props.image;
    }
    
    let triggerButtonStyle
    let triggerClass = "";
    if(props.value === "empty" || props.text === "") {        
        triggerButtonStyle = {
            backgroundImage:"url(" + triggerImage + ")",
            backgroundSize: "contain"
        }
    } else {        
        triggerClass = " " + props.image;
    }

    return(
        <div className={ props.className }>
            <button 
            style = { triggerButtonStyle }
            className={ borderClass + " cell--inner cell--trigger" + triggerClass } 
            onClick = { props.onClick }
            >{ props.text }</button>
        </div>
    );
}

export default Trigger;