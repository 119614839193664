import React from 'react';
import Cell from "./Cell.js";

class SpritePalette extends React.Component {
  
  renderSprite(i, className) {
    let outlineStyle = "none"
    if(i === this.props.active) {
      let borderWidth = 3 / (32 / this.props.spriteWidth);
      outlineStyle = borderWidth + "px solid red";
    }

    let spriteCellStyle = {
      margin: "6px 6px 0 0",
      width: 32,
      height: 32,
      float: "left",      
      transformOrigin: "0 3px 0"
    }

    return(
        <Cell
          key={"SpriteCell" + i}
          imgsrc={this.props.imgsrc}
          spriteWidth={this.props.spriteWidth}
          spriteHeight={this.props.spriteHeight}
          spritesPerRow={this.props.spritesPerRow}
          renderStyle = "crisp-edges"
          outlineStyle={outlineStyle}
          value={i}
          style={spriteCellStyle}        
          onClick={() => this.props.onClick(i)}
          transform ={"scale(" + (32 / Math.max(this.props.spriteWidth, this.props.spriteHeight)) + ")"}
        />
    );
  }
  
  renderNoFill() {
    // merge with above renderSprite
    let spriteCellStyle = {
      margin: "6px 6px 0 0",
      width: this.props.spriteWidth,
      height: this.props.spriteheight,
      float: "left",      
    }
  
    let outlineStyle = "";
    if(this.props.active === 0) {
      outlineStyle = "3px solid red";
    }

    return(
      <Cell 
        imgsrc="./img/no-fill.png"
        spriteWidth={32}
        spriteHeight={32}
        spritesPerRow={1}
        renderStyle = ""
        outlineStyle={outlineStyle}
        style={spriteCellStyle}
        value={null}
        onClick={() => this.props.onClick(0)}
        transform={"scale(1)"}
      />
    );
  }

  render() {  
    var sprites = [];
    for (let i = 1; i <= this.props.totalSprites; i++) {
      sprites.push(this.renderSprite(i));
    }   
    
    let widthClass;
    if(this.props.totalSprites >= 128) {
      widthClass = "sprite-palette--128";
    } 
    else if(this.props.totalSprites >= 64) {
      widthClass = "sprite-palette--64";
    } 
    else if(this.props.totalSprites >= 32) {
      widthClass = "sprite-palette--32";
    }
    else if(this.props.totalSprites >= 16) {
      widthClass = "sprite-palette--16";
    }
    var classes = this.props.className + " " + widthClass;
    //console.log(this.props.totalSprites, "sprites", sprites.length, sprites);
    return (
        <div className={classes}>  
            {sprites}       
            {this.renderNoFill()}     
        </div>
    );
  }
}

export default SpritePalette;