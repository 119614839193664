import React from 'react';
import ReactDOM from 'react-dom';
import config from './config'
import MainNavbar from './MainNavbar';
import ExportModal from './ExportModal';
import ImportModal from './ImportModal';
import SettingsModal from './SettingsModal';
import MapModal from './MapModal';
import RuleSet from './RuleSet';
import Map from './Map';
import PlayEaselJS from './PlayEaselJS';
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import example_brownian_motion from './files/brownian-motion.mft';
import example_maze from './files/maze-instant.mft';
import example_ninja_game from './files/example-ninja.mft';
import example_snake_game from './files/example-snake.mft';
import example_rule22 from './files/rule22.mft';
import example_path_finder from './files/example-path-finding.mft';
import example_block_pushing from './files/example-block-pushing.mft';
import './index.css';

const VERSION = 0.49;

const TRIGGER1 = 0;
const TRIGGER2 = 1;
const TRIGGER3 = 11;
const CELL1 = 2;
const OUTPUT = 7;
const SPECIAL1 = 8;
const ARROW = 10;
let ruleClipboard;
let disabledRuleClipboard;

class Muffit extends React.Component {

  constructor(props) {
    super(props);       
    this.state = props.config;
    this.state.defaultConfig = props.config;
  }

  init() {    
    this.setState(config);
  }

  remapCells(newX, newY, anchor="centre") {
    let i;
    let x, y, z;    
    let newCells = cloneDeep(this.state.mapCells);
    let offsetLeft = 0;
    let offsetRight = 0;
    let offsetTop = 0;
    let offsetBottom = 0;
    //console.log("current H + W:", this.state.cellswide, this.state.cellshigh);
    //console.log("this.state.cellswide:", this.state.cellswide, "this.state.cellshigh:" , this.state.cellshigh, "Length", this.state.mapCells.length);
    //console.log("x:", newX, "Y:" , newY, "Length", this.state.mapCells.length);  
    
    
    if(anchor === "NW" || anchor === "N" || anchor === "NE") {      
      offsetBottom = newY - this.state.cellshigh;          
    }
    if(anchor === "C" || anchor === "W" || anchor === "E") {
      offsetTop = Math.round((newY - this.state.cellshigh) / 2);
      offsetBottom = (newY - this.state.cellshigh) - offsetTop;      
    }
    if(anchor === "SW" || anchor === "S" || anchor === "SE") {
      offsetTop = newY - this.state.cellshigh;
    }
    if(anchor === "NW" || anchor === "W" || anchor === "SW") {
      offsetRight = newX - this.state.cellswide;
    }
    if(anchor === "C" || anchor === "N" || anchor === "S") {
      offsetLeft = Math.round((newX - this.state.cellswide) / 2);
      offsetRight = (newX - this.state.cellswide) - offsetLeft;      
    }
    if(anchor === "NE" || anchor === "E" || anchor === "SE") {
      offsetLeft = newX - this.state.cellswide;
    }
    //console.log("Offset Left", offsetLeft, "Offset Right", offsetRight, "Offset Top", offsetTop, "Offset Bottom", offsetBottom);    
    
    // Loop through every map
    for(z = 0; z < this.state.mapCells.length; z++){
      i = 0;
      // Add top cells      
      if(offsetTop > 0){
        let extraStartCells = new Array(offsetTop * this.state.cellswide).fill(1);
        newCells[z] = extraStartCells.concat(newCells[z]);
      }
      // Trim top cells      
      else if(offsetTop < 0){
        let extraCells = (offsetTop * -1) * this.state.cellswide;
        let cellsDiff = (newCells[z].length) - extraCells;
        newCells[z] = newCells[z].splice(extraCells, cellsDiff);
      }
      for(y = 0; y < newY; y++) {      
        // Trim left cells
        if(offsetLeft < 0){
          newCells[z].splice(i, (offsetLeft * -1));
        }        
        for(x = 0; x < newX; x++) {
          // Add left cells
          if(x < offsetLeft){
            newCells[z].splice(i, 0, 1);
          }
          // Add right cells
          if(x >= (this.state.cellswide + offsetLeft)) {
            newCells[z].splice(i, 0, 1);
          }
          i++;
        }
        // Trim right cells
        if(offsetRight < 0){
          newCells[z].splice(i, (offsetRight * -1));
        }
      }
      // Add bottom cells
      if(offsetBottom > 0){
        let extraEndCells = new Array(offsetBottom * this.state.cellswide).fill(1);
        newCells[z] = newCells[z].concat(extraEndCells);
      }
      // Trim bottom cells
      else if(offsetBottom < 0){
        newCells[z] = newCells[z].slice(0, (newX * newY));
      }
    }
    
    this.setState({
      mapCells: cloneDeep(newCells),
    });
  }

  handleCellClick(i, rule, value) {    
    //console.log("i, rule, value", i, rule, value);
    let rules = this.state.rules.slice();   
    let disabledRules = this.state.disabledRules.slice();
    if(i === 99) {
      // Delete rule
      rules.splice(rule - 1, 1);
      disabledRules.splice(rule - 1, 1);
    }
   else if(i === 100) {
      // Move rule forward
      let tmpRules = cloneDeep(rules[rule]);
      rules[rule] = cloneDeep(rules[rule - 1]);
      rules[rule - 1] = tmpRules;

      let tmpDisabledRules = disabledRules[rule];
      disabledRules[rule] = disabledRules[rule - 1];
      disabledRules[rule - 1] = tmpDisabledRules;
    }
    else if(i === 101) {
      // Move rule backwards
      let tmpRules = cloneDeep(rules[rule - 2]);
      rules[rule - 2] = cloneDeep(rules[rule - 1]);
      rules[rule - 1] = tmpRules;

      let tmpDisabledRules = disabledRules[rule - 2];
      disabledRules[rule - 2] = disabledRules[rule - 1];
      disabledRules[rule - 1] = tmpDisabledRules;
    }
    else if(i === 102) {
      // New rule after
      rules.splice(rule, 0, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      disabledRules.splice(rule, 0, false);
    }
    else if(i === 103) {
      // New rule before
      rules.splice(rule - 1, 0, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      disabledRules.splice(rule - 1, 0, false);
    }
    else if(i === 104) {
      // Copy
      ruleClipboard = cloneDeep(rules[rule - 1]);
      disabledRuleClipboard = disabledRules[rule - 1];
    }
    else if(i === 105) {
      // Paste
      rules[rule - 1] = cloneDeep(ruleClipboard);     
      disabledRules[rule - 1] = disabledRuleClipboard;
    }
    else if(i === 106) {
      // Insert rule group
      rules.splice(rule - 1, 0, "");// Insert blank rule group
      disabledRules.splice(rule - 1, 0, false);
    }
    else if(i === 200) {
      // Toggle enabled/disenabled       
      disabledRules[rule - 1] = !disabledRules[rule - 1];    
    }        
    else if(value === undefined && (i >= CELL1 && i <= OUTPUT)) {     
      rules[rule - 1][i] = this.state.spriteIndex;
    } else if (i === TRIGGER1 || i === TRIGGER2 || i === TRIGGER3 || i === SPECIAL1) {      
      document.body.click(); // Trigger popover to hide
      rules[rule - 1][i] = value;
    } else if (i === ARROW) {
      if(rules[rule - 1][i] === 0) {
        rules[rule - 1][i] = 1;
        
      } else {
        rules[rule - 1][i] = 0;        
      }
    }

    this.setState({
      rules: rules,
      disabledRules: disabledRules,
      rerender: this.state.rerender + 1,
    });
  }

  handleRightClick(e, i, rule) {    
    e.preventDefault();
    let rules = this.state.rules.slice();  
    rules[rule - 1][i] = 0;
    this.setState({
      rules: rules,
      rerender: this.state.rerender + 1
    });
  }
  handleRightClickMap(e, cell) {   
    e.preventDefault();
    const cells = cloneDeep(this.state.mapCells);
    cells[this.state.activeMap][cell] = 1;
    this.setState({
      mapCells: cells
    });
  }

  handleMapClick(cell) {
    const cells = cloneDeep(this.state.mapCells);
    cells[this.state.activeMap][cell] = this.state.spriteIndex;
    this.setState({
      mapCells: cells
    });
  }

  handleLabelChange(value, i) {
    let rules = cloneDeep(this.state.rules);    
    rules[i - 1] = String(value);
    this.setState({
      rules: rules,      
    });
  }

  handleRemoveLabel(e, value) {
    let rules = cloneDeep(this.state.rules);
    rules.splice(value - 1, 1);
    this.setState({
      rules: rules,      
    });
  }

  handleImportGroup(e, value) {
    this.setState({
      importingGroup: value
    });
  }

  handleExportGroup(e, value) { // Possibly move back down to RuleSet
    let group = {};
    group.label = this.state.rules[value - 1];
    group.rules = [];
    for(let i = value; typeof this.state.rules[i] === "object"; i++){
      group.rules.push(cloneDeep(this.state.rules[i]));      
    }    
    downloadObjectAsJson(group, "test", ".grp");
  }
  
  handleGroupLoaded(str, i) {
    let group = JSON.parse(str);
    //console.log("HANDLE GROUP LOADED");
    let rules = cloneDeep(this.state.rules);    
    let disabledRules = this.state.disabledRules.slice(); 
    for(i = 0; i < group.rules.length; i++){
      rules.unshift(group.rules[i]);
      disabledRules.unshift(false);
    }    
    rules.unshift(group.label);    
    disabledRules.unshift(false);

    this.setState({
      rules: rules,   
      disabledRules : disabledRules   
    });

    this.forceUpdate();
  }

  handleUpdate(cell, value){
    const cells = cloneDeep(this.state.mapCells);
    cells[this.state.activeMap][cell] = value;
    this.setState({
      mapCells: cells,
    });
  } 

  /*
  // No longer used by settings window
  handleUpdateSettings(prop, value){        
    this.setState({      
      [prop]: Number(value)
    });    
  }
  */

  openFile(file) {
    this.setState({ 
      modalShow: false, 
    });
  }
  
  setSprite(spriteIndex) {
    this.setState({ spriteIndex: spriteIndex });
  }

  setBorderCell(e) {
    this.setState({ bordercell: this.state.spriteIndex });
  }

  handleTabSelected(e) {
    this.setState({ activeTab: e });
  }

  exportRules() {
    var saveObj = {};
    //console.log(getImageData("sprite-sheet"));
    // Loop through properties instead?
    saveObj.version = VERSION;
    saveObj.rules = this.state.rules.slice();
    saveObj.disabledRules = this.state.disabledRules.slice();
    //saveObj.labels = this.state.labels.slice();
    saveObj.map = cloneDeep(this.state.mapCells);
    saveObj.mapNames = this.state.mapNames.slice();
    saveObj.spriteSheet = this.state.spriteSheet; //getImageData("sprite-sheet")
    saveObj.imgsrc = this.state.imgsrc; //getImageData("sprite-sheet")
    saveObj.rulesimgsrc = this.state.rulesImgSrc; //getImageData("sprite-sheet")
    saveObj.cellswide = this.state.cellswide;
    saveObj.cellshigh = this.state.cellshigh;    
    saveObj.cellswide = this.state.cellswide;
    saveObj.cellshigh = this.state.cellshigh;
    saveObj.cellwidth = this.state.cellwidth;
    saveObj.cellheight = this.state.cellheight;
    saveObj.spriteWidth = this.state.spriteWidth;
    saveObj.spriteHeight = this.state.spriteHeight;
    saveObj.totalSprites = this.state.totalSprites;
    saveObj.spriteSheetWidth = this.state.spriteSheetWidth;
    saveObj.spriteSheetHeight = this.state.spriteSheetHeight;
    saveObj.stageWide = this.state.stageWide;
    saveObj.stageHigh = this.state.stageHigh;
    saveObj.persistent = this.state.persistent;
    saveObj.worldWrap = this.state.worldWrap;
    saveObj.rulespritesonmap = this.state.rulespritesonmap;
    saveObj.timer1 = this.state.timer1;
    saveObj.timer2 = this.state.timer2;
    saveObj.bordercell = this.state.bordercell;
    saveObj.audio = cloneDeep(this.state.audio);
    saveObj.audiolabels = this.state.audiolabels.slice();
    saveObj.soundtrack = cloneDeep(this.state.soundtrack);
    saveObj.soundtracklabel = this.state.soundtracklabel;
    saveObj.randomLow = this.state.randomLow.slice();
    saveObj.randomHigh = this.state.randomHigh.slice();
    saveObj.randomUnique = this.state.randomUnique.slice();
    saveObj.triggerRepeatA = this.state.triggerRepeatA;
    saveObj.triggerRepeatB = this.state.triggerRepeatB;
    saveObj.triggerRepeatC = this.state.triggerRepeatC;
    saveObj.triggerRepeatD = this.state.triggerRepeatD;
    saveObj.resizinganchor = this.state.resizinganchor;
    saveObj.stageanchor = this.state.stageanchor;
    downloadObjectAsJson(saveObj, "test");
  }

  
  handleReset() {
    //if(this.state.programState === "playing") {
      const cells = cloneDeep(this.state.mapCells);
      this.setState({
        programState:"playing",
        mapCells: cells
      });
    //}
  }
 
  handleToggleMenu(e){
    if(e === false){
      document.activeElement.blur();
    }
  }

  handleNewFile(e){
    this.init();
  }

  selectSpriteSheet(value) {
    //console.log("selectSpriteSheet", value);
    if(value === "mines.png"){
      this.setState({      
        spriteSheetWidth: 256,
        spriteSheetHeight: 128,
        spriteWidth: 32,
        spriteHeight: 32,
        cellwidth : 32,
        cellheight : 32,
        totalSprites: 32
      });
    }
    else if(value === "shapes.png"){
      this.setState({      
        spriteSheetWidth: 256,
        spriteSheetHeight: 256,
        spriteWidth: 32,
        spriteHeight: 32,
        cellwidth : 32,
        cellheight : 32,
        totalSprites: 64
      });
    }    
    else if(value === "owls.png"){
      this.setState({      
        spriteSheetWidth: 128,
        spriteSheetHeight: 128,
        spriteWidth: 16,
        spriteHeight: 16,
        cellwidth : 32,
        cellheight : 32,
        totalSprites: 64
      });
    }
    else if(value === "maze.png"){
      this.setState({    
        spriteSheetWidth: 256,
        spriteSheetHeight: 96,          
        spriteWidth: 32,
        spriteHeight: 32,
        cellwidth : 32,
        cellheight : 32,
        totalSprites: 24,
        spritesPerRow: 8
      });
    }        
    else if(value !== "snake.png" && value !== "ninja.png") {      
      this.setState({   
        spriteSheetWidth: 256,
        spriteSheetHeight: 64,
        spriteWidth: 16,
        spriteHeight: 16,
        cellwidth : 16,
        cellheight : 16,
        totalSprites: 16
      });
    } else if(value === "zzt.png"){
      this.setState({
        spriteSheetWidth: 128,
        spriteSheetHeight: 56,
        spriteWidth: 16,
        spriteHeight: 28,
        cellwidth : 16,
        cellheight : 28,
        totalSprites: 16
      });    
    } else {
      this.setState({
        spriteWidth: 32,
        spriteHeight: 32,
        cellwidth : 32,
        cellheight : 32,
        totalSprites: 16        
      });     
    }
    //console.log(value, this.state.spriteWidth, this.state.spriteHeight );
    this.setState({ 
      imgsrc: "./img/" + value,
      rulesImgSrc: "./img/" + value
    });
  }

  loadExample(value) {
    var fileToLoad;
    if(value === "brownian-motion") {
      fileToLoad = example_brownian_motion;
    }
    else if(value === "block-pushing") {
      fileToLoad = example_block_pushing;
    }
    else if(value === "rule22") {
      fileToLoad = example_rule22;
    }    
    else if(value === "ninja-game") {
      fileToLoad = example_ninja_game;
    }
    else if(value === "snake-game") {
      fileToLoad = example_snake_game;
    }
    else if(value === "maze"){
      fileToLoad = example_maze;
    }
    else if(value === "path-finder"){
      fileToLoad = example_path_finder;
    }
    
    fetch(fileToLoad)
    .then(r => r.text())
    .then(text => {
      this.handleFileLoaded(text);
    });
  }

  handleFileLoaded(str) {
    let data = JSON.parse(str);       
    let spriteSheet, imgsrc;
    
    if(data.imgsrc) {
      imgsrc = data.imgsrc;
    }
    else if(data.spriteSheet) {
      spriteSheet = "./img/" + data.spriteSheet;
      imgsrc = "./img/" + data.spriteSheet;
    }
    else {
      spriteSheet = "./img/shapes.png";
      imgsrc = "./img/shapes.png";            
    } 
    
    let rulesImgSrc         
    if(data.rulesimgsrc){
      rulesImgSrc = data.rulesimgsrc;
    } else {
      rulesImgSrc = imgsrc;
    }    

    // Load audio
    let audio;
    if(data.audio) {
      audio = cloneDeep(data.audio);
    } else {
      let audio1 = '/sounds/bump.wav';
      let audio2 = '/sounds/collide1.wav';
      let audio3 = '/sounds/pickup.wav';
      let audio4 = '/sounds/destroy2.wav';
      let audio5 = '/sounds/explode.wav';
      let audio6 = '/sounds/hit.wav';
      let audio7 = '/sounds/sand.wav';
      let audio8 = '/sounds/teleport.wav';
      let audio9 = '/sounds/completelevel.wav';
      audio = [audio1, audio2, audio3, audio4, audio5, audio6, audio7, audio8, audio9] 
    }

    // Load soundtrack
    let soundtrack;
    if(data.soundtrack) {
      soundtrack = cloneDeep(data.soundtrack);
    } else {
      soundtrack = "";
    }

    this.selectSpriteSheet(spriteSheet);
    
    let spriteWidth = Number(this.setSaveValue("spriteWidth", data))
    let spriteSheetWidth = Number(this.setSaveValue("spriteSheetWidth", data));                  
    let spritesPerRow = Number(spriteSheetWidth) / Number(spriteWidth);
            
    this.setState ({            
      rules: cloneDeep(data.rules),
      disabledRules: this.setSaveValue("disabledRules", data).slice(),
      mapCells: cloneDeep(data.map),
      mapNames: this.setSaveValue("mapNames", data).slice(),
      imgsrc: imgsrc,
      rulesImgSrc: rulesImgSrc,
      cellswide: Number(this.setSaveValue("cellswide", data)),
      cellshigh: Number(this.setSaveValue("cellshigh", data)),
      cellwidth: Number(this.setSaveValue("cellwidth", data)),
      cellheight: Number(this.setSaveValue("cellheight", data)),
      spriteWidth: spriteWidth,
      spriteHeight: Number(this.setSaveValue("spriteHeight", data)),
      spriteSheetWidth: Number(this.setSaveValue("spriteSheetWidth,", data)),
      spriteSheetHeight: Number(this.setSaveValue("spriteSheetHeight", data)),            
      spritesPerRow: spritesPerRow,            
      totalSprites: Number(this.setSaveValue("totalSprites", data)),
      stageWide: Number(this.setSaveValue("stageWide", data)),
      stageHigh: Number(this.setSaveValue("stageHigh", data)),
      persistent: Boolean(this.setSaveValue("persistent", data)),
      worldWrap: Boolean(this.setSaveValue("worldWrap", data)),
      rulespritesonmap : Boolean(this.setSaveValue("rulespritesonmap", data)),
      timer1: Number(this.setSaveValue("timer1", data)),
      timer2: Number(this.setSaveValue("timer2", data)),
      bordercell: Number(this.setSaveValue("bordercell", data)), 
      programState: "playing",    
      audio: audio,
      audiolabels: this.setSaveValue("audiolabels", data).slice(),
      soundtrack: soundtrack,
      soundtracklabel: this.setSaveValue("soundtracklabel", data),
      randomLow: this.setSaveValue("randomLow", data).slice(),
      randomHigh: this.setSaveValue("randomHigh", data).slice(),
      randomUnique: this.setSaveValue("randomUnique", data).slice(),
      triggerRepeatA: Number(this.setSaveValue("triggerRepeatA", data)),
      triggerRepeatB: Number(this.setSaveValue("triggerRepeatB", data)),
      triggerRepeatC: Number(this.setSaveValue("triggerRepeatC", data)),
      triggerRepeatD: Number(this.setSaveValue("triggerRepeatD", data)),
      resizinganchor: this.setSaveValue("resizinganchor", data),
      stageanchor: this.setSaveValue("stageanchor", data)
    });       
  }

  setSaveValue(saveValue, data) {    
    if(data[saveValue]) {      
      return data[saveValue];
    } else {
      return this.state.defaultConfig[saveValue];
    }
  }
  
  handleOpenSettings() {
    this.setState({ settingsModalShow: true });
  }

  handleChangeMap(e) {    
    this.setState({ activeMap: e.target.value });
  }
  handleRuleGroupSelected(e) {
    this.setState({groupIndex: e.target.value});
  }
  handleShowManageMaps() {
    this.setState({ mapModalShow: true});  
  }

  handleMoreRules() {
    this.addEmptyRules(this.state.rules.length - 1, 1);
  }

  handleLotsMoreRules() {    
    this.addEmptyRules(this.state.rules.length - 1, 10);
  }

  handleLessRules() {
    this.removeRules(this.state.rules.length - 1, 1);
  }

  handleLotsLessRules() {
    //this.removeRules(this.state.rules.length - 1, 4);
    this.removeTrailingRules(this.state.rules.length - 1);
  }

  addEmptyRules(ruleIndex, count = 1) {    
    let rules = this.state.rules.slice();   
    let disabledRules = this.state.disabledRules.slice();    
    for(let i = 0; i < count; i++){
      rules.splice(ruleIndex + count, 0, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      disabledRules.splice(ruleIndex + count, 0, false);
    }
    this.setState({
      rules: rules,
      disabledRules: disabledRules,
      rerender: this.state.rerender + 1,
    });
  }

  removeRules(ruleIndex, count = 1) {
    let rules = this.state.rules.slice();   
    let disabledRules = this.state.disabledRules.slice();    
    for(let i = 0; i < count; i++){
      rules.splice(ruleIndex - count, 1);
      disabledRules.splice(ruleIndex - count, 1);
    }
    this.setState({
      rules: rules,
      disabledRules: disabledRules,
      rerender: this.state.rerender + 1,
    });
  }

  removeTrailingRules(ruleIndex) {
    let rules = this.state.rules.slice();
    let disabledRules = this.state.disabledRules.slice();
    while(ruleIndex >= 0 && this.state.rules[ruleIndex].toString() === "0,0,0,0,0,0,0,0,0,0,0,0"){
        disabledRules.splice(ruleIndex, 1);
        rules.splice(ruleIndex, 1);
        ruleIndex--;            
      }
      this.setState({
        rules: rules,
        disabledRules: disabledRules,
        rerender: this.state.rerender + 1,
      });
  }

  settingsModalClose(newSettings) {
    // Remap cells if needed
    if(newSettings.cellswide !== this.state.cellswide || newSettings.cellshigh !== this.state.cellshigh){
      this.remapCells(Number(newSettings.cellswide), Number(newSettings.cellshigh), String(newSettings.resizinganchor));
    } 
    let spritesPerRow = Number(newSettings.spriteSheetWidth) / Number(newSettings.spriteWidth);
    let totalSprites = Math.floor(spritesPerRow * (Math.floor(Number(newSettings.spriteSheetHeight) / Number(newSettings.spriteHeight))));
    //console.log("totalSprites", totalSprites, "spritesPerRow", spritesPerRow);
    //console.log("spriteSheetHeight", newSettings.spriteSheetHeight, "spriteHeight", newSettings.spriteHeight);
    // Update settings
    this.setState({ 
      settingsModalShow: false,
      cellswide: newSettings.cellswide,
      cellshigh: newSettings.cellshigh,
      stageWide: newSettings.stageWide,
      stageHigh: newSettings.stageHigh,
      cellwidth: newSettings.cellwidth,
      cellheight: newSettings.cellheight,
      persistent: newSettings.persistent,
      worldWrap: newSettings.worldWrap,
      rulespritesonmap: newSettings.rulespritesonmap,
      timer1: newSettings.timer1,
      timer2: newSettings.timer2,
      bordercell: newSettings.bordercell,  
      audio: cloneDeep(newSettings.audio),
      audiolabels: newSettings.audiolabels.slice(),
      soundtrack: cloneDeep(newSettings.soundtrack),
      soundtracklabel: newSettings.soundtracklabel,
      randomLow: newSettings.randomLow.slice(),
      randomHigh: newSettings.randomHigh.slice(),
      randomUnique: newSettings.randomUnique.slice(),
      triggerRepeatA: newSettings.triggerRepeatA,
      triggerRepeatB: newSettings.triggerRepeatB,
      triggerRepeatC: newSettings.triggerRepeatC,
      triggerRepeatD: newSettings.triggerRepeatD,
      resizinganchor: newSettings.resizinganchor,
      stageanchor: newSettings.stageanchor,
      imgsrc: newSettings.imgsrc,
      rulesImgSrc: newSettings.rulesImgSrc,
      spriteHeight: newSettings.spriteHeight,
      spriteWidth: newSettings.spriteWidth,
      spriteSheetWidth : newSettings.spriteSheetWidth,
      spriteSheetHeight : newSettings.spriteSheetHeight,
      spritesPerRow : spritesPerRow,
      totalSprites : totalSprites
    });
  };

  handleManageMapOK(newMapSettings) {    
    this.setState({
      // Also add mapCells
      mapModalShow: false,
      mapNames: newMapSettings.mapNames,
      mapCells: newMapSettings.mapCells
    });
  }

  handleShowSpriteSettings(e) {
    this.setState({
      activeTab: "Sprites"
    });
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false });
    let handleMapModalClose  = () => this.setState({ mapModalShow: false });
    
    //console.log("this.state.rulespritesonmap", this.state.rulespritesonmap);
    let mapImgSrc;
    if(this.state.rulespritesonmap){      
      mapImgSrc = String(this.state.rulesImgSrc);
    } else {
      mapImgSrc = String(this.state.imgsrc);
    }
    //console.log("this.state.rulesImgSrc", this.state.rulesImgSrc);
    //let settingsModalClose = () => this.setState({ settingsModalShow: false });
    //console.log("this.state.randomUnique", this.state.randomUnique);
    
    return (
        <div>
          <MainNavbar
            mapNames={this.state.mapNames}
            rules={this.state.rules.slice()}
            onSelect={(e) => this.selectSpriteSheet(e)} 
            onNew={(e) => this.handleNewFile(e)} 
            onToggleMenu={(e) => this.handleToggleMenu(e)} 
            onLoadExample={(e) => this.loadExample(e)} 
            onExportRules={() => this.exportRules()}
            onImportRules={() => this.importRules()}
            onSpriteSettings={(e) => this.handleShowSpriteSettings(e)}
            onFileLoaded={(e) => this.handleFileLoaded(e)}      
            onOpenSettings={() => this.handleOpenSettings()}
            onChangeMap={(e) => this.handleChangeMap(e)}
            onShowManageMaps={(e) => this.handleShowManageMaps(e)}
            onFocusRuleGroup={(e) => this.handleRuleGroupSelected(e)}
            onGroupLoaded={(e) => this.handleGroupLoaded(e)}            
            programState={this.state.programState}
          />
          <Switch>
            <Route 
              path="/" exact 
              render={props => 
                <RuleSet
                  {...props}                  
                  rules={this.state.rules.slice()}
                  disabledRules={this.state.disabledRules.slice()}
                  spriteIndex={this.state.spriteIndex}
                  spriteSheet={this.state.spriteSheet} 
                  spriteWidth={this.state.spriteWidth} 
                  spriteHeight={this.state.spriteHeight}
                  spritesPerRow={this.state.spritesPerRow}
                  totalSprites={this.state.totalSprites}
                  rulesImgSrc = {this.state.rulesImgSrc} 
                  audiolabels={cloneDeep(this.state.audiolabels)}
                  triggers={cloneDeep(this.state.triggers)}
                  specialsConfig={cloneDeep(this.state.specialsConfig)}
                  groupIndex={Number(this.state.groupIndex)}
                  handleLabelChange={(e, label) => this.handleLabelChange(e, label)}
                  handleRemoveLabel={(e, label) => this.handleRemoveLabel(e, label)}
                  handleExportGroup={(e, label) => this.handleExportGroup(e, label)}
                  onGroupLoaded={(e, i) => this.handleGroupLoaded(e, i)}
                  onRuleClick={(i, rule, value) => this.handleCellClick(i, rule, value)} 
                  onRightClick={(e, i, rule, value) => this.handleRightClick(e, i, rule, value)} 
                  onSpriteSelect={(e) => this.setSprite(e)}
                  onMoreRules={(e) => this.handleMoreRules(e)}
                  onLotsMoreRules={(e) => this.handleLotsMoreRules(e)}
                  onLessRules={(e) => this.handleLessRules(e)}
                  onLotsLessRules={(e) => this.handleLotsLessRules(e)}                  
                />
              }
            />
            <Route 
              path="/map" exact 
              render={props => 
                <Map
                  {...props}
                  rules={this.state.rules}
                  cells={this.state.mapCells[this.state.activeMap].slice()}
                  cellswide={this.state.cellswide}
                  cellshigh={this.state.cellshigh}
                  stageWide={this.state.stageWide}
                  stageHigh={this.state.stageHigh}
                  cellwidth={this.state.cellwidth}
                  cellheight={this.state.cellheight}
                  spriteIndex={this.state.spriteIndex}
                  imgsrc = {mapImgSrc}
                  spriteWidth={this.state.spriteWidth} 
                  spriteHeight={this.state.spriteHeight}
                  totalSprites={this.state.totalSprites}
                  spritesPerRow={this.state.spritesPerRow}
                  stageanchor={this.state.stageanchor}
                  onClick={(cell) => this.handleMapClick(cell)}
                  onRightClick={(e, cell) => this.handleRightClickMap(e, cell)} 
                  onSpriteSelect={(e) => this.setSprite(e)}
                />
              }handleRightClick
            />
            <Route 
              path="/play" exact 
              render={props => 
                <PlayEaselJS
                  {...props}
                  rules={this.state.rules}
                  disabledRules={this.state.disabledRules}
                  cells={cloneDeep(this.state.mapCells)}
                  activeMap={this.state.activeMap}
                  cellswide={this.state.cellswide}
                  cellshigh={this.state.cellshigh}
                  stageWide={this.state.stageWide}
                  stageHigh={this.state.stageHigh}
                  cellwidth={this.state.cellwidth}
                  cellheight={this.state.cellheight}
                  persistent={this.state.persistent}
                  worldWrap={this.state.worldWrap}
                  rulespritesonmap={this.state.rulespritesonmap}
                  timer1={this.state.timer1}
                  timer2={this.state.timer2}
                  bordercell={this.state.bordercell}
                  spriteIndex={this.state.spriteIndex}
                  spriteSheet={this.state.spriteSheet} 
                  imgsrc = {this.state.imgsrc}
                  spriteWidth={this.state.spriteWidth} 
                  spriteHeight={this.state.spriteHeight}
                  spritesPerRow={this.state.spritesPerRow}
                  totalSprites={this.state.totalSprites}
                  programState={this.state.programState}
                  audio={cloneDeep(this.state.audio)}
                  soundtrack={cloneDeep(this.state.soundtrack)}
                  randomLow={this.state.randomLow}
                  randomHigh={this.state.randomHigh}
                  randomUnique={this.state.randomUnique}
                  triggerRepeatA={this.state.triggerRepeatA}
                  triggerRepeatB={this.state.triggerRepeatB}
                  triggerRepeatC={this.state.triggerRepeatC}
                  triggerRepeatD={this.state.triggerRepeatD}
                  stageanchor={this.state.stageanchor}
                  onReset={() => this.handleReset()}
                  onUpdate={(cell, value) => this.handleUpdate(cell, value)}
                />
              }
            />
            <Route
              path="/settings" exact              
              render={props =>
                <SettingsModal
                  {...props}
                  show={true} 
                  onHide={(newSettings) => this.settingsModalClose(newSettings)}             
                  cellswide = {this.state.cellswide}
                  cellshigh = {this.state.cellshigh}
                  stageWide={this.state.stageWide}
                  stageHigh={this.state.stageHigh}
                  cellwidth = {this.state.cellwidth}
                  cellheight = {this.state.cellheight}
                  persistent = {String(this.state.persistent)}
                  worldWrap = {String(this.state.worldWrap)}      
                  rulespritesonmap={String(this.state.rulespritesonmap)}
                  timer1 = {this.state.timer1}
                  timer2 = {this.state.timer2}
                  bordercell = {this.state.bordercell}
                  audio={cloneDeep(this.state.audio)}
                  audiolabels={this.state.audiolabels.slice()}
                  soundtrack={cloneDeep(this.state.soundtrack)}
                  soundtracklabel={this.state.soundtracklabel}
                  randomLow={this.state.randomLow.slice()}
                  randomHigh={this.state.randomHigh.slice()}
                  randomUnique={this.state.randomUnique.slice()}
                  triggerRepeatA={this.state.triggerRepeatA}
                  triggerRepeatB={this.state.triggerRepeatB}
                  triggerRepeatC={this.state.triggerRepeatC}
                  triggerRepeatD={this.state.triggerRepeatD}
                  resizinganchor={String(this.state.resizinganchor)}
                  stageanchor={String(this.state.stageanchor)}
                  className="settings"
                  spriteIndex={this.state.spriteIndex}
                  imgsrc = {mapImgSrc}
                  rulesImgSrc={cloneDeep(this.state.rulesImgSrc)}
                  spriteWidth={this.state.spriteWidth}
                  spriteHeight={this.state.spriteHeight}
                  spriteSheetWidth={this.state.spriteSheetWidth}
                  spriteSheetHeight={this.state.spriteSheetHeight}
                  onSpriteSheetLoaded={(e) => this.handleSpriteSheetLoaded(e)}
                  totalSprites={this.state.totalSprites}
                  spritesPerRow={this.state.spritesPerRow}
                  activeTab={this.state.activeTab}
                  onClick={(cell) => this.handleMapClick(cell)}
                  onRightClick={(e, cell) => this.handleRightClickMap(e, cell)} 
                  onSpriteSelect={(e) => this.setSprite(e)}
                  onChangeBorderCell={(e) => this.setBorderCell(e)}
                  onTabSelected={(e) => this.handleTabSelected(e)}
                />
              }
            />
            <Route path="/export" exact component={ExportModal} />
            <Route path="/open" exact component={ExportModal} />
          </Switch>
          <ImportModal 
            show={this.state.modalShow} 
            onHide={modalClose} 
            openfile={(file) => this.openFile(file)} 
          />
          <MapModal
            show={this.state.mapModalShow}             
            mapnames={this.state.mapNames}
            mapcells={this.state.mapCells}
            onHide={handleMapModalClose}
            onAccept={(newMapSettings) => this.handleManageMapOK(newMapSettings)}
          >
          </MapModal>
          <img
            alt="sprite sheet" 
            id="sprite-sheet"
            //src = {`${imgsrc}`}
          />          
        </div>
    );
  }
}

// ========================================
// Move into helper functions import
// ========================================

function downloadObjectAsJson(exportObj, exportName, extension = ".mft"){
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href",     dataStr);
  downloadAnchorNode.setAttribute("download", exportName + extension);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

/*
function getImageData(imageID){
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  var img = document.getElementById(imageID);
  context.drawImage(img, 0, 0 );
  return context.getImageData(0, 0, img.width, img.height);
}
*/

// ========================================

ReactDOM.render(
  <Router>
    <Muffit config={ config }/>
  </Router>, 
  document.getElementById('root')
);
