import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

function RuleMenu(props){
    return(
        <div className="rule--header">
            <span 
                onClick={ (e) => props.onClick(200, props.ruleNo)}
                className="rule--number">{props.ruleLabel}
            </span>            
            <Dropdown>
                <Dropdown.Toggle className="rule--menu-icon" split id="dropdown-custom-2" />
                <Dropdown.Menu>
                    <Dropdown.Item onSelect={(e) => props.onClick(99, props.ruleNo)}>Delete</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => props.onClick(100, props.ruleNo)}>Move forward</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => props.onClick(101, props.ruleNo)}>Move back</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => props.onClick(102, props.ruleNo)}>New after</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => props.onClick(103, props.ruleNo)}>New before</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => props.onClick(104, props.ruleNo)}>Copy</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => props.onClick(105, props.ruleNo)}>Paste</Dropdown.Item>
                    <Dropdown.Item onSelect={(e) => props.onClick(106, props.ruleNo)}>Start rule group</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default RuleMenu;