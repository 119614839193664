import React from 'react';

function Special(props) {
    
    return(
        <div className={props.className}>
            <button className={"special " + props.style} onClick = {props.onClick}>
                {props.label}
            </button>
        </div>
    );
}

export default Special;