import arrow_left_icon from './img/triggers/arrow_left.png';
import arrow_up_icon from './img/triggers/arrow_up.png';
import arrow_right_icon from './img/triggers/arrow_right.png';
import arrow_down_icon from './img/triggers/arrow_down.png';
import button1_icon from './img/triggers/button1.png';
import button2_icon from './img/triggers/button2.png';
import mouse_down_icon from './img/triggers/mouse_down.png';
import mouse_up_icon from './img/triggers/mouse_up.png';
import drag_icon from './img/triggers/drag.png';

const images = require.context('../public/img', true);

const DEFAULT_CELLS_WIDE = 25;
const DEFAULT_CELLS_HIGH = 15;
const DEFAULT_CELL_WIDTH = 32;
const DEFAULT_CELL_HEIGHT = 32;

let emptyRules = [];
let disabledRules = [];
for (let i = 0; i < 50; i++) {
    emptyRules.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    disabledRules[i] = false;
}
let cells = new Array(DEFAULT_CELLS_WIDE * DEFAULT_CELLS_HIGH).fill(1);

let audio1 = '/sounds/bump.wav';
let audio2 = '/sounds/collide1.wav';
let audio3 = '/sounds/pickup.wav';
let audio4 = '/sounds/destroy2.wav';
let audio5 = '/sounds/explode.wav';
let audio6 = '/sounds/hit.wav';
let audio7 = '/sounds/sand.wav';
let audio8 = '/sounds/teleport.wav';
let audio9 = '/sounds/completelevel.wav';     

export const appConfig = {
        rules: emptyRules,  
        disabledRules: disabledRules,  
        modalShow: false,
        settingsModalShow: false,
        spriteModalShow: false,
        mapModalShow: false,
        groupIndex: -1,
        rerender: 0,         
        // Sprites   
        spriteSheet: "shapes.png",
        imgsrc: images(`./shapes.png`),
        rulesImgSrc: images(`./shapes.png`),
        spriteWidth: 32,
        spriteHeight: 32,
        totalSprites: 64,
        spriteSheetWidth: 256,
        spriteSheetHeight: 256,
        spritesPerRow: 8, // was 8 make custom and default to 8 again
        spriteIndex: 1,  
        programState:"playing",                
        mapCells: [cells.slice(), cells.slice(), cells.slice(), cells.slice(), cells.slice(), cells.slice(), cells.slice(), cells.slice(), cells.slice()],
        mapNames: ["Map 1", "Map 2", "Map 3", "Map 4", "Map 5", "Map 6"],
        activeMap: 0,                
        cellswide: DEFAULT_CELLS_WIDE,
        cellshigh: DEFAULT_CELLS_HIGH,
        cellwidth: DEFAULT_CELL_WIDTH,
        cellheight: DEFAULT_CELL_HEIGHT,
        stageWide: DEFAULT_CELLS_WIDE,
        stageHigh: DEFAULT_CELLS_HIGH,
        persistent: false,
        worldWrap: false,
        rulespritesonmap: false,
        timer1: 400,
        timer2: 800,
        bordercell: 1, // Sprite index for border cells starting from 1
        audio: [audio1, audio2, audio3, audio4, audio5, audio6, audio7, audio8, audio9],
        audiolabels: ["Sound 1", "Sound 2", "Sound 3", "Sound 4", "Sound 5", "Sound 6", "Sound 7", "Sound 8", "Sound 9"],
        soundtrack: "",
        soundtracklabel: "",
        randomLow: [0,  1,  51,  1, 26, 51, 76,   1,  51,  1, 26, 51,  76],
        randomHigh: [0, 50, 100, 25, 50, 75, 100, 50, 100, 25, 50, 75, 100],
        randomUnique:  [false, false, false , false , false, false, false, true, true, true, true, true, true],
        triggerRepeatA : 15,
        triggerRepeatB : 20,
        triggerRepeatC : 30,
        triggerRepeatD : 40,
        resizinganchor: "NW",
        stageanchor: "C",
        activeTab: "Map",
        triggers: [
            {image: arrow_left_icon, text: "", style: "popover__icon", index: 1},
            {image: arrow_up_icon, text: "", style: "popover__icon", index: 2},
            {image: arrow_right_icon, text: "", style: "popover__icon", index: 3},
            {image: arrow_down_icon, text: "", style: "popover__icon", index: 4},            
            {image: "trigger__step", text: "T1", style: "popover__icon", index: 12},
            {image: "trigger__step", text: "T2", style: "popover__icon", index: 13},
            {image: button1_icon, text: "", style: "popover__icon", index: 6},
            {image: button2_icon, text: "", style: "popover__icon", index: 7},            
            {image: "trigger__step", text: "1", style: "popover__icon", index: 10},
            {image: "trigger__step", text: "2", style: "popover__icon", index: 11},
            {image: "trigger__step", text: "3", style: "popover__icon", index: 14},
            {image: "trigger__step", text: "4", style: "popover__icon", index: 15},            
            {image: "trigger__trigger", text: "T1", style: "popover__icon", index: 20},
            {image: "trigger__trigger", text: "T2", style: "popover__icon", index: 21},
            {image: "trigger__trigger", text: "T3", style: "popover__icon", index: 22},
            {image: "trigger__trigger", text: "T4", style: "popover__icon", index: 23},
            {image: "trigger__trigger", text: "T5", style: "popover__icon", index: 31},
            {image: "trigger__trigger", text: "T6", style: "popover__icon", index: 32},
            {image: "trigger__trigger", text: "T7", style: "popover__icon", index: 33},
            {image: "trigger__trigger", text: "T8", style: "popover__icon", index: 34},
            {image: "trigger__trigger", text: "T9", style: "popover__icon", index: 35},
            {image: "trigger__trigger", text: "T10", style: "popover__icon", index: 36},
            {image: "trigger__trigger", text: "T11", style: "popover__icon", index: 37},
            {image: "trigger__trigger", text: "T12", style: "popover__icon", index: 38},
            {image: "trigger__trigger", text: "T13", style: "popover__icon", index: 39},
            {image: "trigger__trigger", text: "T14", style: "popover__icon", index: 50},
            {image: "trigger__trigger", text: "T15", style: "popover__icon", index: 51},
            {image: "trigger__trigger", text: "T16", style: "popover__icon", index: 52},
            {image: "trigger__trigger", text: "T17", style: "popover__icon", index: 53},
            {image: "trigger__trigger", text: "T18", style: "popover__icon", index: 54},
            {image: "trigger__trigger", text: "T19", style: "popover__icon", index: 55},
            {image: "trigger__trigger", text: "T20", style: "popover__icon", index: 56},
            {image: "trigger__trigger", text: "T21", style: "popover__icon", index: 57},
            {image: "trigger__trigger", text: "T22", style: "popover__icon", index: 58},
            {image: "trigger__trigger", text: "T23", style: "popover__icon", index: 59},
            {image: "trigger__trigger", text: "T24", style: "popover__icon", index: 60},
            {image: mouse_down_icon, text: "", style: "popover__icon", index: 25},
            {image: mouse_up_icon, text: "", style: "popover__icon", index: 26},
            {image: drag_icon, text: "", style: "popover__icon", index: 27},
            {image: "trigger__any", text:  "Any", style: "popover__icon", index: 30},
            {image: "", text:  "", style: "popover__icon", index: 0}
        ],
        specialsConfig: [  
            {label:"Sound 1", type: "sound", style:"special__sound", index:28},
            {label:"Sound 2", type: "sound", style:"special__sound", index:2},
            {label:"Sound 3", type: "sound", style:"special__sound", index:3},
            {label:"Sound 4", type: "sound", style:"special__sound", index:4},
            {label:"Sound 5", type: "sound", style:"special__sound", index:5},
            {label:"Sound 6", type: "sound", style:"special__sound", index:6},
            {label:"Sound 7", type: "sound", style:"special__sound", index:7},
            {label:"Sound 8", type: "sound", style:"special__sound", index:8},
            {label:"Sound 9", type: "sound", style:"special__sound", index:9},
            {label:"Trigger 1", type: "trigger", style:"special__trigger", index:10},
            {label:"Trigger 2", type: "trigger", style:"special__trigger", index:11},
            {label:"Trigger 3", type: "trigger", style:"special__trigger", index:12},
            {label:"Trigger 4", type: "trigger", style:"special__trigger", index:13},
            {label:"Trigger 5", type: "trigger", style:"special__trigger", index:31},
            {label:"Trigger 6", type: "trigger", style:"special__trigger", index:32},
            {label:"Trigger 7", type: "trigger", style:"special__trigger", index:33},
            {label:"Trigger 8", type: "trigger", style:"special__trigger", index:34},
            {label:"Trigger 9", type: "trigger", style:"special__trigger", index:35},            
            {label:"Trigger 10", type: "trigger", style:"special__trigger", index:36},
            {label:"Trigger 11", type: "trigger", style:"special__trigger", index:37},
            {label:"Trigger 12", type: "trigger", style:"special__trigger", index:38},
            {label:"Trigger 13", type: "trigger", style:"special__trigger", index:39},
            {label:"Trigger 14", type: "trigger", style:"special__trigger", index:50},
            {label:"Trigger 15", type: "trigger", style:"special__trigger", index:51},
            {label:"Trigger 16", type: "trigger", style:"special__trigger", index:52},
            {label:"Trigger 17", type: "trigger", style:"special__trigger", index:53},
            {label:"Trigger 18", type: "trigger", style:"special__trigger", index:54},
            {label:"Trigger 19", type: "trigger", style:"special__trigger", index:55},
            {label:"Trigger 20", type: "trigger", style:"special__trigger", index:56},
            {label:"Trigger 21", type: "trigger", style:"special__trigger", index:57},
            {label:"Trigger 22", type: "trigger", style:"special__trigger", index:58},
            {label:"Trigger 23", type: "trigger", style:"special__trigger", index:59},
            {label:"Trigger 24", type: "trigger", style:"special__trigger", index:60},
            {label:"Next map", type: "action", style:"special__action", index:16},
            {label:"Last map", type: "action", style:"special__action", index:17},
            {label:"Restart", type: "action", style:"special__action", index:18},
            {label:"Map 1", type: "action", style:"special__action", index:22},
            {label:"Map 2", type: "action", style:"special__action", index:23},
            {label:"Map 3", type: "action", style:"special__action", index:24},
            {label:"Map 4", type: "action", style:"special__action", index:25},
            {label:"Map 5", type: "action", style:"special__action", index:26},
            {label:"Map 6", type: "action", style:"special__action", index:27}, 
            {label:"Scroll N", type: "action", style:"special__action", index:40},
            {label:"Scroll S", type: "action", style:"special__action", index:41},
            {label:"Scroll E", type: "action", style:"special__action", index:42},
            {label:"Scroll W", type: "action", style:"special__action", index:43},
            {label:"+50", type: "action", style:"special__action", index:44},
            {label:"+100", type: "action", style:"special__action", index:45},
            {label:"+250", type: "action", style:"special__action", index:46},
            {label:"+500", type: "action", style:"special__action", index:47},
            {label:"None", type: "blank", style:"special__blank", index:0},
            {label:"None", type: "blank", style:"special__blank", index:1}
          ]
    }

export default appConfig
