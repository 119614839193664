import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form';

    // Make a render component instead of a label?
    class Label extends React.Component {
          
      /*
      handleLabelChange = e => {
        console.log("ONChange");
        this.setState({      
          [e.target.name]: e.target.value
        });
      } 
      */   

      handleKeyPress = (e) => {
        if(e.key === 'Enter'){
          e.target.blur();
        }
      }

      render() {
        return (
          <div id={ makeSafeID(this.props.label) } style={this.props.style} className="label">   
            <Form.Control            
                index={this.props.index}
                className="label__input"
                name="label"
                type="input"            
                placeholder="Rule group"
                value={this.props.label}
                onChange={(e) => this.props.handleLabelChange(e.target.value, this.props.index)}
                onBlur={(e) => this.props.handleLabelChange(e.target.value, this.props.index)}
                onKeyPress={this.handleKeyPress}
            />
            <Dropdown>
                <Dropdown.Toggle className="group--menu-icon" split id="dropdown-group">...</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onSelect={(e) => this.props.handleRemoveLabel(e, this.props.index)}>Delete</Dropdown.Item>                    
                    <Dropdown.Item onSelect={(e) => this.props.handleExportGroup(e, this.props.index)}>Export...</Dropdown.Item>                                        
                </Dropdown.Menu>                
            </Dropdown>
            {this.props.children}
          </div>
        );
      }    
    }
    
    function makeSafeID(s) {
      let id = encodeURI(s).toLowerCase().replace(/\.|%[0-9a-z]{2}/gi, '');
      return id;
    }

export default Label;