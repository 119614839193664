import React from 'react';
import Button from 'react-bootstrap/Button';
import Rule from './Rule';
import Label from './Label';
import SpritePalette from './SpritePalette';
import cloneDeep from 'lodash/cloneDeep';

function RuleSet(props) {    
    let ruleNo = 0;
    let rules = [];
    let isNewGroup = false;

    let specialsConfig = props.specialsConfig;
    let specialsConfigIndex = [];
    for(let config in specialsConfig){
        specialsConfigIndex[specialsConfig[config].index] = cloneDeep(specialsConfig[config]);
    }

    let triggersIndex = [];
    for(let config in props.triggers) {
        triggersIndex[props.triggers[config].index] = cloneDeep(props.triggers[config]);
    }
        
    for (let i = 0; i < props.rules.length; i++) {       
        if(typeof props.rules[i] !== "string"){           
            ruleNo++;
            let disabledClass = "";
            if(props.disabledRules[i]){
                disabledClass = "rule--disabled"
            }                      
            if(props.groupIndex === -1 || (i >= props.groupIndex && !isNewGroup)) {
                rules.push(                    
                    <Rule 
                        key = {i}
                        index = {i + 1} 
                        ruleNo = {ruleNo}
                        rule = {props.rules[i]} 
                        className = {disabledClass} 
                        spriteSheet = {props.spriteSheet}
                        spriteWidth = {props.spriteWidth}
                        spriteHeight = {props.spriteHeight}
                        spritesPerRow = {props.spritesPerRow}
                        imgsrc = {props.rulesImgSrc}
                        triggers = {props.triggers}
                        triggersIndex = {triggersIndex}
                        specialsConfig = {specialsConfig}
                        specialsConfigIndex = {specialsConfigIndex}
                        onClick = {props.onRuleClick} 
                        onContextMenu = {props.onRightClick}                     
                    />
                );
            }
        } else if(props.groupIndex === -1 || ((i  >= props.groupIndex) && !isNewGroup)) {            
            let labelIndex = i;
            i++;
            let groupRules = [];
            while(typeof props.rules[i] !== "string" && i < props.rules.length){
                ruleNo++;
                let disabledClass = "";                
                if(props.disabledRules[i]){
                    disabledClass = "rule--disabled"
                }                                    
                groupRules.push(
                    <Rule
                        key={i}
                        index={i + 1} 
                        ruleNo={ruleNo}
                        rule={props.rules[i]} 
                        className={disabledClass} 
                        spriteSheet={props.spriteSheet}
                        spriteWidth={props.spriteWidth}
                        spriteHeight={props.spriteHeight}
                        spritesPerRow={props.spritesPerRow}
                        imgsrc={props.rulesImgSrc}
                        triggers={props.triggers}
                        triggersIndex = {triggersIndex}
                        specialsConfig={specialsConfig}
                        specialsConfigIndex={specialsConfigIndex}
                        onClick={props.onRuleClick} 
                        onContextMenu={props.onRightClick}                     
                    />
                );                
                i++;
            }
            rules.push(<Label 
                onGroupLoaded={props.onGroupLoaded}
                key={9000 + labelIndex}
                index={labelIndex + 1}
                label={props.rules[labelIndex]}
                handleLabelChange={props.handleLabelChange}
                handleRemoveLabel={props.handleRemoveLabel}
                handleExportGroup={props.handleExportGroup}
            >{groupRules}</Label>);
            i--;
            isNewGroup = true;
        }
    }

    let rulesWidth;
    if(props.totalSprites >= 128) {
        rulesWidth = "rules-wrapper--128";
      } 
      else if(props.totalSprites >= 64) {
        rulesWidth = "rules-wrapper--64";
      } 
      else if(props.totalSprites >= 32) {
        rulesWidth = "rules-wrapper--32";
      }
      else if(props.totalSprites >= 0) {
        rulesWidth = "rules-wrapper--16";
      }

    //console.log("totalSprites ###", props.totalSprites);

    return (
        <div>
            <SpritePalette 
                active={props.spriteIndex}
                imgsrc = {props.rulesImgSrc}
                className="sprite-palette bg-dark"
                spriteWidth={props.spriteWidth} 
                spriteHeight={props.spriteHeight} 
                spritesPerRow={props.spritesPerRow} 
                totalSprites={props.totalSprites} 
                onClick={props.onSpriteSelect}                
            />
            <div className={rulesWidth}>
                {rules}
                <div className="rules__button-wrapper">
                    <Button className="rules__button-plus" variant="light" onClick={props.onMoreRules} >+</Button>
                    <Button className="rules__button-minus" variant="light" onClick={props.onLessRules} >-</Button>
                    <Button className="rules__button-plus-plus" variant="light" onClick={props.onLotsMoreRules} >++</Button>
                    <Button className="rules__button-minus-minus" variant="light" onClick={props.onLotsLessRules} >&lt;&lt;</Button>
                </div>
            </div>
        </div>
    );
}
export default RuleSet;