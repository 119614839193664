import React, {useEffect} from 'react';
import KeyTrigger from './KeyTrigger';
import { SpriteSheet, Sprite, Stage } from "@createjs/easeljs";
import cloneDeep from 'lodash/cloneDeep';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Button from 'react-bootstrap/Button';

const TIMER1 = 12;
const TIMER2 = 13;
//const images = require.context('../public/img', true);

var stage;
var spriteSheet;
var cellsGrid = [];
let gridWidth;
let gridHeight;
let stageWide;
let stageHigh;
let cellWidth;
let cellHeight;
let offsetX;
let offsetY;
let gridLength;
let spriteWidth;
let spriteHeight;
let persistent;
let worldWrap;
let timer1;
let timer2;
let borderCell;
let activeCells;
let originalCells;
let audio = [];
let randomLow = [];
let randomHigh = [];
let randomUnique = [];
let triggerRepeatA;
let triggerRepeatB;
let triggerRepeatC;
let triggerRepeatD;
let cachedRules;
let cachedDisabledRules;
let rules;
let triggersUsed;
let rulePatterns
let activeMap = 1;
let enterFrame;
let id1;
let id2;
let isDown = false;
let cachedAudio = [];
let soundtrack;
let score = 0;
let highScore = 0;

function PlayEaselJS(props) {
    
    useEffect(() => {
        init(props);
        return function cleanup() {            
            if(id1) {
                clearInterval(id1);
            }
            if(id2) {
                clearInterval(id2);
            }
            if(soundtrack) {
                soundtrack.pause();
            }
        };
    },[props]);
    
    const handle = useFullScreenHandle();
    let fullscreenStyle = {
        width: props.stageWide * props.cellwidth/*,
        left: (window.screen.availWidth - (props.stageWide * props.cellwidth)) * 0.5*/
    }

    return (    
        <div>                        
            <FullScreen handle={handle}>                                
                <div className="play-screen__wrappper" style={fullscreenStyle}>
                    
                    <Button className="fullscreen-button" variant="dark" onClick={handle.enter}>Fullscreen</Button>
                    <Button className="restart-button" variant="danger" onClick={props.onReset}>Restart</Button>
                    <div className="play-screen__score" id="score">SCORE: { score }</div>
                    <canvas className="play-screen" id="play-wrapper" width={props.stageWide * props.cellwidth} height={props.stageHigh * props.cellheight}></canvas>
                    <div className="keys-wrapper"> 
                        <KeyTrigger style={{display: "none"}} keyCode={0} label="Any" onTrigger={() => keyPress(30, 0)} />               
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={37} label="Left" onTrigger={() => keyPress(1, 0)} />
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={38} label="Up" onTrigger={() => keyPress(2, 0)} />
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={39} label="Right" onTrigger={() => keyPress(3, 0)} />
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={40} label="Down" onTrigger={() => keyPress(4, 0)} /> 
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={87} label="W" onTrigger={() => keyPress(2, 0)} />
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={65} label="A" onTrigger={() => keyPress(1, 0)} />
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={83} label="S" onTrigger={() => keyPress(4, 0)} /> 
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={68} label="D" onTrigger={() => keyPress(3, 0)} />                                            
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={90} label="z" onTrigger={() => keyPress(6, 0)} />
                        <KeyTrigger style={{margin: "10px", display: "none"}} keyCode={88} label="x" onTrigger={() => keyPress(7, 0)} />                                
                        <KeyTrigger style={{display:"none"}} keyCode={82} label="r" onTrigger={() => keyPress(99, 0)} />
                    </div>
                </div>
            </FullScreen>
        </div>
    );
}

function keyPress(key){
    if(key === 99){
        resetGame();
        return;
    }
    updateCells(key);
    stage.update();
}

function gc(char) {
    if(char === undefined){
        return String.fromCharCode(97 + borderCell);
    } else{
        return String.fromCharCode(97 + char);
    }
}

function clearTimers(){
    if(enterFrame){
        clearInterval(enterFrame);
    }
    if(id1) {        
        clearInterval(id1);
    }
    if(id2) {
        clearInterval(id2);        
    }
}

function checkConditionals(conditions, r) {        
    for(let i in conditions){        
        if(r >= randomLow[conditions[i]] && r <= randomHigh[conditions[i]]){
            return i;
        }
    }
    return -1;
}

function init(props) {    
    gridWidth = props.cellswide;
    gridHeight = props.cellshigh;
    stageHigh = props.stageHigh;
    stageWide = props.stageWide;
    cellWidth = props.cellwidth;
    cellHeight = props.cellheight;
    spriteWidth = props.spriteWidth;
    spriteHeight = props.spriteHeight;    
    persistent = props.persistent;
    worldWrap = props.worldWrap;
    timer1 = props.timer1;
    timer2 = props.timer2;
    borderCell = props.bordercell;
    
    let storedHighScore = localStorage.getItem("cellulrHighscore");
    if(storedHighScore) {
        highScore = storedHighScore;
    }
    
    if(props.stageanchor === "NW" || props.stageanchor === "W" || props.stageanchor === "SW") {
        offsetX = 0;
    }
    else if(props.stageanchor === "N" || props.stageanchor === "C" || props.stageanchor === "S") {
        offsetX = Math.floor((gridWidth - props.stageWide) * 0.5);
    }
    else if(props.stageanchor === "NE" || props.stageanchor === "E" || props.stageanchor === "SE") {
        offsetX = gridWidth - props.stageWide;
    }

    if(props.stageanchor === "NW" || props.stageanchor === "N" || props.stageanchor === "NE") {
        offsetY = 0;
    }
    else if(props.stageanchor === "W" || props.stageanchor === "C" || props.stageanchor === "E") {
        offsetY = Math.floor((gridHeight - props.stageHigh) * 0.5);
    }
    else if(props.stageanchor === "SE" || props.stageanchor === "S" || props.stageanchor === "SW") {
        offsetY = (gridHeight - props.stageHigh);
    }

    if(typeof(rules) === "undefined" || JSON.stringify(cachedRules) !== JSON.stringify(props.rules) || JSON.stringify(cachedDisabledRules) !== JSON.stringify(props.disabledRules)) {        
        cachedRules = cloneDeep(props.rules);
        cachedDisabledRules = props.disabledRules.slice();
        let activeRules = [];
        for(let i = 0; i < props.rules.length; i++){
            if(!props.disabledRules[i]){                
                activeRules.push(props.rules[i].slice());
            }                    
        }
        
        var rulesObj = convertRules(activeRules);
        rules = rulesObj.rules;
        triggersUsed = rulesObj.triggersUsed;
        rulePatterns = rulesObj.rulePatterns;
    }    
    
    activeMap = props.activeMap;
    activeCells = cloneDeep(props.cells);
    originalCells = cloneDeep(props.cells);
    cellsGrid = [];
    stage = new Stage("play-wrapper");
    /*
    Ticker.addEventListener("tick", handleTick);
    function handleTick(event) {    
        stage.update();
    }
    */
    var spriteData = {
        images: [props.imgsrc],
        frames: {width:spriteWidth, height:spriteHeight},
    };
    
    spriteSheet = new SpriteSheet(spriteData);
    audio = cloneDeep(props.audio);
    randomLow = props.randomLow.slice();
    randomHigh = props.randomHigh.slice();
    randomUnique = props.randomUnique.slice();
    triggerRepeatA = props.triggerRepeatA;
    triggerRepeatB = props.triggerRepeatB;
    triggerRepeatC = props.triggerRepeatC;
    triggerRepeatD = props.triggerRepeatD;

    // Preload audio
    for(let i = 0; i < audio.length; i++) {
        cachedAudio[i] = new Audio(audio[i]);
    }

    //Load and play soundtrack
    if(props.soundtrack && props.soundtrack !== "") {
        soundtrack = new Audio(props.soundtrack);
        soundtrack.loop = true;
        soundtrack.play();
    }

    // Preload Spritesheet
    if (!spriteSheet.complete) {
        // not preloaded, listen for the complete event:
        spriteSheet.addEventListener("complete", handleSpritesLoaded);
    }
    else {        
        renderGrid(activeCells[activeMap]);
        stage.update();
        start();
    }
    // See https://createjs.com/docs/easeljs/classes/DisplayObject.html#event_mouseout for EaselJS mouse events
    if(triggersUsed[25]) {
        stage.addEventListener('mousedown', event => {
            let offscreenCells = (((gridHeight - stageHigh) * 0.5) * gridWidth) + ((gridWidth - stageWide) * 0.5);
            let cell = (((event.target.y / cellHeight) * gridWidth) + (event.target.x / cellWidth)) + offscreenCells;
            updateCells(25, 0, cell);  
            stage.update();
        });
    }
    if(triggersUsed[26]) {
        stage.addEventListener('click', event => {
            let offscreenCells = (((gridHeight - stageHigh) * 0.5) * gridWidth) + ((gridWidth - stageWide) * 0.5);
            let cell = (((event.target.y / cellHeight) * gridWidth) + (event.target.x / cellWidth)) + offscreenCells;
            updateCells(26, 0, cell);  
            stage.update();
        });
    }
    if(triggersUsed[27]) {
        stage.enableMouseOver(10);
        stage.addEventListener('mouseover', event => {
            if(isDown) {
                let offscreenCells = (((gridHeight - stageHigh) * 0.5) * gridWidth) + ((gridWidth - stageWide) * 0.5);
                let cell = (((event.target.y / cellHeight) * gridWidth) + (event.target.x / cellWidth)) + offscreenCells;
                updateCells(27, 0, cell);  
                stage.update();    
            }
        });
        stage.addEventListener('mousedown', event => {
            isDown = true;
        });
        stage.addEventListener('pressup', event => {
            isDown = false;
        });    
    }

    function handleSpritesLoaded(event) {        
        renderGrid(activeCells[activeMap]);
        stage.update();
        start();
    }
}

function start(){        
    //var startTime = Date.now();
    stage.isFirst = false;    
    stage.updateCells = 0;
    stage.threshold = 5000;
    clearTimers();    
    
    // Init 1
    if(triggersUsed[10]) {
        updateCells(10);         
    }

    // Init 2
    if(triggersUsed[11]) {
        updateCells(11); 
    }

    // Init 3
    if(triggersUsed[14]) {
        updateCells(14); 
    }
    
    // Init 4
    if(triggersUsed[15]) {
        updateCells(15); 
    }
    
    stage.update();
    //console.log("Init 1, 2, 3, 4 Frame ms: ", Date.now() - startTime);

    // Timer 1
    if(triggersUsed[TIMER1]) {
        id1 = setInterval(function() {                                     
            updateCells(TIMER1); // Timer 1                 
            stage.update();
        }, timer1);
    }
    
    /// Timer 2
    if(triggersUsed[TIMER2]) {
        id2 = setInterval(function() {
            updateCells(TIMER2); //Timer 2
            stage.update();
        }, timer2);
    }
}

function renderGrid(cells) {    
    //console.log("Render Grid", cells, gridHeight, gridWidth);    
    //console.log("RenderGrid", offsetY + " / " + offSetEndY, "cells", cells.length, cells);
    //console.log("offsetX", offsetX, "offsetY", offsetY, "GW,GH", gridWidth, gridHeight);
    //console.log("Min", offsetX, "max", offsetX + stageWide);
    //console.log("CELLS", cells);
    let sprite;    
    let i = 0; 
    for (let y = 0; y < gridHeight; y++) {
        for (let x = 0; x < gridWidth; x++) {
            if(x >= offsetX && x < (offsetX + stageWide) && y >= offsetY && y < (offsetY + stageHigh)) {
                //if(!cellsGrid[i]){                
                //console.log("Render:" + cells[i], cells[i] - 1);
                sprite = new Sprite(spriteSheet);                                
                sprite.scaleX = cellWidth / spriteWidth;
                sprite.scaleY = cellHeight / spriteHeight;                                    
                cellsGrid[i] = sprite;               
                stage.addChild(sprite);
                sprite.x = (x - offsetX) * cellWidth;
                sprite.y = (y - offsetY) * cellHeight;                                                            
                if(typeof cells[i] == "number") {
                    sprite.gotoAndStop(cells[i] - 1);                
                } else {
                    //console.log("OBJECT", activeMap);
                    sprite.gotoAndStop(cells[activeMap][i] - 1);                
                }
                //}
            }                           
            i++; 
            //else {
            //    sprite = cellsGrid[i];
            //}            
            
            //if(x >= offsetX && x < (gridWidth - offsetX) && y >= offsetY && y < offSetEndY) {                
            //cellsGrid[i].visible = true;
            //}
            //else {
            //    cellsGrid[i].visible = false;
            //}            
        }           
    }
    gridLength = i;
}

function refreshGrid(cells){
    //console.log("Refresh Grid");    
    let sprite;    
    let i = 0; 
    for (let y = 0; y < gridHeight; y++) {
        for (let x = 0; x < gridWidth; x++) {
            if(x >= offsetX && x < (offsetX + stageWide) && y >= offsetY && y < (offsetY + stageHigh)) {
                sprite = cellsGrid[i];;
                sprite.gotoAndStop(cells[i] - 1);                
            }                           
            i++;         
        }           
    }
}

function updateCells(trigger1, additionalTriggers = 0, targetedCell = null) {   
    //updated++;
    //console.log("update Cells", trigger1, additionalTriggers)    
    stage.updateCells++; 
    //console.log(stage.updateCells);
    if(stage.updateCells > 500){
        cellsGrid[1].gotoAndStop(1);
        cellsGrid[2].gotoAndStop(2);
        cellsGrid[3].gotoAndStop(3);
        cellsGrid[4].gotoAndStop(4);
        cellsGrid[5].gotoAndStop(5);
        cellsGrid[6].gotoAndStop(6);
        cellsGrid[7].gotoAndStop(7);
        cellsGrid[8].gotoAndStop(8);
        console.log("ERROR EXCEEDED MAX LOOP!");
        return false;
    }
    let arraySpecials = [];
    // Call before Trigger    
    let r = Math.ceil(Math.random() * 100);    
    if(additionalTriggers === 0 && triggersUsed[trigger1]) {        
        stage.updateCells = 0;        
        
        if(triggersUsed[trigger1][1]) {
            updateCells(trigger1, 1, targetedCell);
        }
        if(triggersUsed[trigger1][2]) {
            while(updateCells(trigger1, 2, targetedCell));        
        }
    }    
    /*
    if(props.programState !== "playing") {
        return;
    }
    */
    let arrayRandomCells = [];
    let arrayRandomOutputs = [];
    let oldCells = activeCells[activeMap].slice();
    let newCells = activeCells[activeMap].slice();  
    let ruleIndex;
    let isMet;
    let pattern;
    let isUpdated = false;
    let x = 0;
    let objCells = {};
    let arrayCells;
    let rr;
    let conditionalOutput;
    let conditionalSpecial;
    let conditionalIndex;
    for(let i = 0; i < gridLength; i++) {
        if(targetedCell != null){
            i = targetedCell; // Hacky
        }  
        x++;             
        rr = Math.ceil(Math.random() * 100);    
        // Assign Cells
        objCells = assignCells(oldCells, i, x);
        x = objCells.x; // Kludge fix for border cells
        arrayCells = [objCells.upCell, objCells.leftCell, objCells.middleCell, objCells.rightCell, objCells.downCell];
        isMet = false;

        for(pattern = 0; pattern < rulePatterns.length; pattern++){
            conditionalOutput = -1;
            conditionalSpecial = -1;
            ruleIndex = gc(trigger1) + createRuleFromPattern(arrayCells, rulePatterns[pattern]) + gc(additionalTriggers);        
            //console.log("^^^^^", ruleIndex);
            // Add cell to list if a a rule with a star conditional resolves
            if(rules[ruleIndex]) {
                if(!rules[ruleIndex].conditional) {
                    isMet = true;
                }
                else if(rules[ruleIndex].conditional === 13) {                      
                    // for random;
                    arrayRandomCells.push(i);
                    arrayRandomOutputs.push(rules[ruleIndex].output);
                    isMet = false;
                } else {
                    if(randomUnique[rules[ruleIndex].conditional]) {
                        conditionalIndex = checkConditionals(rules[ruleIndex].conditionals, rr);
                    } else {
                        conditionalIndex = checkConditionals(rules[ruleIndex].conditionals, r);
                    }
                    conditionalOutput = rules[ruleIndex].conditionalOutputs[conditionalIndex];
                    conditionalSpecial = rules[ruleIndex].conditionalSpecials[conditionalIndex];
                    if(conditionalOutput >= 0) {
                        isMet = true;
                    } else {
                        isMet = false;
                    }
                } 
                // Resolve regular rules        
                if(isMet) {  
                    isUpdated = true;            
                    newCells[i] = rules[ruleIndex].output;
                    if(cellsGrid[i]) {
                        if(conditionalOutput >= 0){
                            newCells[i] = conditionalOutput;
                            cellsGrid[i].gotoAndStop(conditionalOutput - 1);
                        } 
                        else {
                            cellsGrid[i].gotoAndStop(newCells[i] - 1);
                        }                        
                        // Add code to Exit loop if ruleResolves
                        if(conditionalSpecial >= 0){
                            arraySpecials.push(conditionalSpecial);
                        } else {
                            if(rules[ruleIndex].special1) {   
                                arraySpecials.push(rules[ruleIndex].special1)
                            }
                        }
                    }
                }
            }
        }  
        if(targetedCell != null){
            break; // hacky
        }
    }
    // If any star conditions were triggered pick one at random
    if(arrayRandomCells.length > 0) {        
        isUpdated = true;
        r = Math.floor(Math.random() * arrayRandomCells.length);
        newCells[arrayRandomCells[r]] = arrayRandomOutputs[r];
        if(cellsGrid[arrayRandomCells[r]]){
            cellsGrid[arrayRandomCells[r]].gotoAndStop(arrayRandomOutputs[r] - 1);
        } 
        //if(rules[ruleIndex].special1) {
        //    triggerSpecial(rules[ruleIndex].special1);
        //}
    }

    activeCells[activeMap] = newCells.slice();
    
    if(additionalTriggers === 0 && triggersUsed[trigger1]) {
        if(triggersUsed[trigger1][3]) {    
            while(updateCells(trigger1, 3, targetedCell));
        }
        if(triggersUsed[trigger1][4]) {        
            updateCells(trigger1, 4, targetedCell);
        }               
        if(triggersUsed[trigger1][5]) {
            for(let i = 0; i < 5; i++){
                updateCells(trigger1, 5, targetedCell);
            }
        }
        if(triggersUsed[trigger1][6]) {
            for(let i = 0; i < 10; i++){
                updateCells(trigger1, 6, targetedCell);
            }
        }
        if(triggersUsed[trigger1][7]) {
            for(let i = 0; i < 25; i++){
                updateCells(trigger1, 7, targetedCell);
            }
        }
        if(triggersUsed[trigger1][8]) {
            for(let i = 0; i < 50; i++){
                updateCells(trigger1, 8, targetedCell);
            }
        }
        if(triggersUsed[trigger1][9]) {
            for(let i = 0; i < triggerRepeatA; i++){
                updateCells(trigger1, 9, targetedCell);
            }
        }
        if(triggersUsed[trigger1][10]) {
            for(let i = 0; i < triggerRepeatB; i++){
                updateCells(trigger1, 10, targetedCell);
            }
        }
        if(triggersUsed[trigger1][11]) {
            for(let i = 0; i < triggerRepeatC; i++){
                updateCells(trigger1, 11, targetedCell);
            }
        }
        if(triggersUsed[trigger1][12]) {
            for(let i = 0; i < triggerRepeatD; i++){
                updateCells(trigger1, 12, targetedCell);
            }
        }
        //stage.update();
    }      
    if(arraySpecials.length > 0) {        
        for(var s in arraySpecials){
            if(triggerSpecial(arraySpecials[s])) return;              
        }
    }
    
    return isUpdated;    
}

function createRuleFromPattern(arrayCells, rulePattern) {
    //console.log("createRuleFromPattern", arrayCells + "----" + rulePattern);
    //console.log("cells:", objCells.upCell, objCells.leftCell, objCells.middleCell, objCells.rightCell, objCells.downCell);
    //console.log("rulePattern:", rulePattern);
    var rule = "";
    for(var i = 0; i < 5; i++) {
        if(rulePattern.charAt(i) === "1") {
            rule += arrayCells[i];
        } else {
            rule += "a"
        }
    }
    return rule;   
}


function assignCells(oldCells, i, x) {
    // Using: worldWrap, gridWidth    
    let objCells = {};    
    if(x === 1) {
        if(worldWrap === true) {
            objCells.leftCell = gc(oldCells[i + (gridWidth - 1)]);
        } else {            
            objCells.leftCell = String.fromCharCode(97 + borderCell);            
        }
    } else {
        objCells.leftCell = gc(oldCells[i - 1]);                             
    }
    if(x === gridWidth) {                        
        x = 0;
        if(worldWrap === true) {
            objCells.rightCell = gc(oldCells[i - (gridWidth - 1)]);
        } else {
            objCells.rightCell = String.fromCharCode(97 + borderCell);            
        }
    } else {
        objCells.rightCell = gc(oldCells[i + 1]);
    }     

    if(i - gridWidth < 0){                        
        if(worldWrap === true) {
            objCells.upCell = gc(oldCells[i + (gridWidth * (gridHeight - 1))]);
        } else {
            objCells.upCell = String.fromCharCode(97 + borderCell);                
        }
    }
    else {
        objCells.upCell = gc(oldCells[i - gridWidth]);                    
    }        
    if(i + gridWidth > oldCells.length) {
        if(worldWrap === true) {
            objCells.downCell = gc(oldCells[i - (gridWidth * (gridHeight - 1))]);
        }
        else {
            objCells.downCell = String.fromCharCode(97 + borderCell);
        }
    } else {
        objCells.downCell = gc(oldCells[i + gridWidth]);
    }        
    objCells.middleCell = gc(oldCells[i]);    
    objCells.x = x; // Klduge fix
    return objCells;
}    

function triggerSpecial(special) {
    // Play Sound
    if((special >= 1 && special <= 9) || special === 28) {
        if(special === 28) {
            special = 1;
        }        
        cachedAudio[special - 1].play();           
        return false;
    }
    // Activate Triggers 
    else if(special === 10) {
        updateCells(20);
        //renderGrid(activeCells[activeMap]);
        return false;
    }
    else if(special === 11) {        
        updateCells(21);
        return false;
    }
    else if(special === 12) {
        updateCells(22);
        return false;
    }
    else if(special === 13) {
        updateCells(23);
        return false;
    }
    else if(special === 31) {
        updateCells(31);
        return false;
    }
    else if(special === 32) {
        updateCells(32);
        return false;
    }
    else if(special === 33) {
        updateCells(33);
        return false;
    }
    else if(special === 34) {
        updateCells(34);
        return false;
    }
    else if(special === 35) {
        updateCells(35);
        return false;
    } else if(special === 36) {
        updateCells(36);
        return false;
    } else if(special === 37) {
        updateCells(37);
        return false;
    } else if(special === 38) {
        updateCells(38);
        return false;
    } else if(special === 39) {
        updateCells(39);
        return false;
    } else if(special === 50) {
        updateCells(50);
        return false;
    } else if(special === 51) {
        updateCells(51);
        return false;
    } else if(special === 52) {
        updateCells(52);
        return false;
    } else if(special === 53) {
        updateCells(53);
        return false;
    } else if(special === 54) {
        updateCells(54);
        return false;
    } else if(special === 55) {
        updateCells(55);
        return false;
    } else if(special === 56) {
        updateCells(56);
        return false;
    } else if(special === 57) {
        updateCells(57);
        return false;
    } else if(special === 58) {
        updateCells(58);
        return false;
    } else if(special === 59) {
        updateCells(59);
        return false;
    } else if(special === 60) {
        updateCells(60);
        return false;
    }
    // Load next map
    else if(special === 16 && Number(activeMap) < 99) {
        activeMap++;
        if(!persistent) {
            activeCells = cloneDeep(originalCells);
        }
        start();
        resetGame(!persistent);
        return true;
    }
    // Load prev map
    else if(special === 17 && Number(activeMap) > 0) {
        activeMap--;
        if(!persistent) {
            activeCells = cloneDeep(originalCells);
        }
        start();
        resetGame(!persistent);
        return true;
    }
    // Load map 1 to 6    
    else if(special >= 22 && special < 28) {
        activeMap = special - 22;        
        if(!persistent) {
            activeCells = cloneDeep(originalCells);
        }
        start();
        resetGame(!persistent);
        return true;
    }
    else if(special === 40) { 
        if(offsetY > 0) {
            offsetY -= 1;
            renderGrid(activeCells[activeMap]);        
            return true;
        }
    }
    else if(special === 41) {
        if(offsetY + stageHigh < gridHeight){
            offsetY += 1;
            renderGrid(activeCells[activeMap]);
            return true;
        }
    }
    else if(special === 42) {
        if(offsetX + stageWide < gridWidth) {
            offsetX += 1;
            renderGrid(activeCells[activeMap]);
            return true;
        }
    }
    else if(special === 43) {
        if(offsetX > 0) {
            offsetX -= 1;
            renderGrid(activeCells[activeMap]);
            return true;
        }
    }
    else if(special === 44) {
        score += 50;        
        updateScore();
        
    }
    else if(special === 45) {
        score += 100;
        updateScore();
    }
    else if(special === 46) {
        score += 250;
        updateScore();
    }
    else if(special === 47) {
        score += 500;
        updateScore();
    }
    else if(special === 18) { // Restart        
        setTimeout(resetGame, 2000);
        return true;
    }      
} 

function updateScore() {    
    if(score > Number(highScore)) {      
        highScore = score;
        localStorage.setItem("cellulrHighscore", String(highScore));
    }    
    document.getElementById("score").innerHTML = "<span style='color:yellow'>HI: " + highScore +"</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp SCORE: " + score;
}

function resetGame(isResettingCells = true){
    score = 0;
    updateScore();
    offsetX = Math.floor((gridWidth - stageWide) * 0.5);
    offsetY = Math.floor((gridHeight - stageHigh) * 0.5);

    if(isResettingCells){
        activeCells = cloneDeep(originalCells);        
    }

    // Init 1
    if(triggersUsed[10]) {
        updateCells(10);         
    }

    // Init 2
    if(triggersUsed[11]) {
        updateCells(11); 
    }

    // Init 3
    if(triggersUsed[14]) {
        updateCells(14); 
    }

    // Init 4
    if(triggersUsed[15]) {
        updateCells(15); 
    }

    refreshGrid(activeCells[activeMap]);
    stage.update();
}

// Helper functions
function getBit(c){
    if(c === 0) return "0";
    else return "1";
}

function convertRules(rules) {  
  let labelFreeRules  = cloneDeep(rules);
  // Remove labels from rules
  for(let rule = (labelFreeRules.length - 1); rule >= 0; rule--){
    if(typeof labelFreeRules[rule] === "string"){
        labelFreeRules.splice(rule, 1);        
    }
  }
    
  let objRules = {};
  let triggersUsed = []
  let rulePattern = "";
  let rulePattern2 = "";
  let rulePattern3 = "";
  let rulePattern4 = "";
  let tmpRulePatterns = [];
  let rulePatternsMapping = [];
  let t2, t3, t4, t5, t6;
  let isInfinite;
  let allRules = [];
  objRules.rules = [];
  objRules.triggers = [];
  for(let rule of labelFreeRules) {      
      t2 = gc(rule[2]);
      t3 = gc(rule[3]);
      t4 = gc(rule[4]);
      t5 = gc(rule[5]);
      t6 = gc(rule[6]);
      let newRule1 = t2 + t3 + t4 + t5 + t6;
      rulePattern = getBit(rule[2]) + getBit(rule[3]) + getBit(rule[4]) + getBit(rule[5]) + getBit(rule[6]);            
      if(!rulePatternsMapping[rulePattern] && rulePattern !== "00000"){
        rulePatternsMapping[rulePattern] = true;
        tmpRulePatterns.push(rulePattern);
      }

      if((rule[11] === 2 || rule[11] === 3) && rule[4] !== 0 && (rule[4] === rule[7])) {
        isInfinite = true
      } else {
        isInfinite = false;
      }      

      if(!isInfinite) {
        allRules = [];
        allRules.push(newRule1);
                
        if(newRule1 !== "aaaaa") {
            if(rule[10] === 1){
            // Handle arrows
            
            let newRule2 = t6 + t5 + t4 + t3 + t2;
            let newRule3 = t3 + t6 + t4 + t2 + t5;
            let newRule4 = t5 + t2 + t4 + t6 + t3;          
            allRules.push(newRule2);
            allRules.push(newRule3);
            allRules.push(newRule4);

            rulePattern2 = getBit(rule[6]) + getBit(rule[5]) + getBit(rule[4]) + getBit(rule[3]) + getBit(rule[2]);            
            rulePattern3 = getBit(rule[3]) + getBit(rule[6]) + getBit(rule[4]) + getBit(rule[2]) + getBit(rule[5]);            
            rulePattern4 = getBit(rule[5]) + getBit(rule[2]) + getBit(rule[4]) + getBit(rule[6]) + getBit(rule[3]);            
            if(!rulePatternsMapping[rulePattern2] && rulePattern !== "00000"){
                rulePatternsMapping[rulePattern2] = true;
                tmpRulePatterns.push(rulePattern2);
            }
            if(!rulePatternsMapping[rulePattern3] && rulePattern !== "00000"){
                rulePatternsMapping[rulePattern3] = true;
                tmpRulePatterns.push(rulePattern3);
            }
            if(!rulePatternsMapping[rulePattern4] && rulePattern !== "00000"){
                rulePatternsMapping[rulePattern4] = true;
                tmpRulePatterns.push(rulePattern4);
            }
            }        
        } 
        
        if(!rule[11]) rule[11] = 0; // Cover old files without an additionalTrigger
        if(!triggersUsed[rule[0]]){
                triggersUsed[rule[0]] = [];
        }
        triggersUsed[rule[0]][rule[11]] = true; // Track additionals Triggers
        let ruleVal;
        if(allRules){
            for(let newRule of allRules) {   
            ruleVal = gc(rule[0]) + newRule + gc(rule[11]);
            if(!objRules.rules[ruleVal]){
                objRules.rules[ruleVal] = {};
            }
            objRules.rules[ruleVal].output = rule[7];
            if(rule[8] !== 1) {
                objRules.rules[ruleVal].special1 = rule[8]; 
            }
            if(rule[1] !== 0) {
                
                objRules.rules[ruleVal].conditional = rule[1];              
                if(!objRules.rules[ruleVal].conditionals) {
                    objRules.rules[ruleVal].conditionals = [];
                }          
                objRules.rules[ruleVal].conditionals.push(rule[1])
                if(!objRules.rules[ruleVal].conditionalOutputs) {
                    objRules.rules[ruleVal].conditionalOutputs = [];
                }            
                objRules.rules[ruleVal].conditionalOutputs.push(rule[7]);
                if(!objRules.rules[ruleVal].conditionalSpecials) {
                    objRules.rules[ruleVal].conditionalSpecials = [];
                }
                objRules.rules[ruleVal].conditionalSpecials.push(rule[8]);
            }
            }
        }
    }
  }

  // *** Sort rule patterns by least 1's to most ***
  tmpRulePatterns.sort(function(a,b){
    let matchesA = a.match(/1/g).length;
    let matchesB = b.match(/1/g).length;
    return matchesA - matchesB;        
});
  objRules.triggersUsed = triggersUsed;
  objRules.rulePatterns = tmpRulePatterns;
  
  //console.log("Triggers used", objRules.triggersUsed);
  //console.log("Rule patterns", objRules.rulePatterns);
  //console.log("Rules", objRules.rules);
  return objRules;
}

export default PlayEaselJS;
