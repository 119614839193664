import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import cloneDeep from 'lodash/cloneDeep';

class MapModal extends React.Component {
    
    constructor(...args) {
        super(...args);
        
        this.state = { 
            mapNames: this.props.mapnames,  
            mapCells: cloneDeep(this.props.mapnames),
            active: 0,        
            edit: false
        };          
    }    

    componentWillReceiveProps(props) {        
        this.setState({    
            mapNames: this.props.mapnames,
            mapCells: cloneDeep(this.props.mapcells),
            active: 0,        
            edit: false
        });
    }

    handleMapNameEdit = e => {
        //this.props.handleUpdateSettings([e.target.name], e.target.value);
        var newMapNames = this.state.mapNames.slice();
        newMapNames[this.state.active] = e.target.value;        
        this.setState({      
            mapNames: newMapNames
          });
    }

    /*--- Button Handlers ---*/
    handleCopy = e => {
        this.clipboardMapNames = String(this.state.mapNames[this.state.active]);
        this.clipboardCells = this.state.mapCells[this.state.active].slice();
    }

    handlePaste = e => {        
        //let newMapNames = this.state.mapNames.slice();
        //newMapNames[this.state.active] = this.clipboardMapNames;    
        
        let newMapCells = cloneDeep(this.state.mapCells);
        newMapCells[this.state.active] = this.clipboardCells;
        
        this.clipboardCells.slice();
        this.setState({
            //mapNames: newMapNames,
            mapCells: newMapCells
        });
    }

    handleAdd = e => {
        let newMapNames = this.state.mapNames.slice();
        newMapNames.splice(this.state.active + 1, 0, "New map");
        
        let newMapCells = cloneDeep(this.state.mapCells);        
        let newMap = this.state.mapCells[this.state.active].slice();
        newMap.fill('1');
        newMapCells.splice(this.state.active + 1, 0, newMap);

        this.setState({
            mapNames: newMapNames,
            mapCells: cloneDeep(newMapCells),
            active: Number(this.state.active + 1)
        });
    }

    handleRemove = e => {
        let newMapNames = this.state.mapNames.slice();
        newMapNames.splice(this.state.active, 1);

        let newMapCells = cloneDeep(this.state.mapCells);
        newMapCells.splice(this.state.active, 1);

        this.setState({
            mapNames: newMapNames,
            mapCells: cloneDeep(newMapCells)
        });
    }
    
    handleDown = e => {
        if(this.state.active + 2 > this.state.mapNames.length) {
            return; // Disable down button so this isn't possible
        } 
        let newMapNames = this.state.mapNames.slice();
        newMapNames[this.state.active] = String(this.state.mapNames[this.state.active + 1]);
        newMapNames[this.state.active + 1] = String(this.state.mapNames[this.state.active]);
        
        let newMapCells = cloneDeep(this.state.mapCells);
        newMapCells[this.state.active] = this.state.mapCells[this.state.active + 1].slice();
        newMapCells[this.state.active + 1] = this.state.mapCells[this.state.active].slice();

        this.setState({
            mapNames: newMapNames.slice(),
            mapCells: cloneDeep(newMapCells),
            active: Number(this.state.active + 1)
        });
    }

    handleUp = e => {
        if(this.state.active - 1 < 0) {
            return; // Disable down button so this isn't possible
        }
        let newMapNames = this.state.mapNames.slice();
        newMapNames[this.state.active] = String(this.state.mapNames[this.state.active - 1]);
        newMapNames[this.state.active - 1] = String(this.state.mapNames[this.state.active]);
        
        let newMapCells = cloneDeep(this.state.mapCells);
        newMapCells[this.state.active] = this.state.mapCells[this.state.active - 1].slice();
        newMapCells[this.state.active - 1] = this.state.mapCells[this.state.active].slice();


        this.setState({
            mapNames: newMapNames.slice(),
            mapCells: cloneDeep(newMapCells),
            active: Number(this.state.active - 1)
        });
    }
    /*--- End Button Handlers ---*/

    handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            this.setState({ 
                edit: false
            });
        }
    }
    handleBlur= (e) => {
        this.setState({ 
            edit: false
        });
    }

    renderMapListItems() {
        let list = [];
        for(let i in this.state.mapNames){
            if(Number(this.state.active) === Number(i)){
                if(this.state.edit){
                    list.push(
                        <ListGroup.Item 
                            key={'MapItem' + i} 
                            action 
                            active 
                            onClick={() => this.listItemClicked(i)}
                        >
                            <Form.Control 
                                autoFocus
                                name="mapName"
                                editing = {i}
                                type="text" 
                                placeholder={this.state.mapNames[i]} 
                                onChange={this.handleMapNameEdit}
                                onKeyPress={this.handleKeyPress}
                                onBlur={this.handleBlur}
                            />                            
                        </ListGroup.Item>);
                } else {
                    list.push(
                        <ListGroup.Item 
                            key={'MapItem' + i} 
                            action 
                            active 
                            onClick={() => this.listItemClicked(i)}
                        >
                            {this.state.mapNames[i]}
                        </ListGroup.Item>);
                }                
            } else {
                list.push(<ListGroup.Item key={'MapItem' + i} action onClick={() => this.listItemClicked(i)}>{this.state.mapNames[i]}</ListGroup.Item>);
            }            
        }
        return(list);
    }
    
    listItemClicked(i){
        let edit = false;
        if(this.state.active === Number(i)) {
            edit = true;
        }
        this.setState({ 
            edit: edit,
            active: Number(i)
        });
    }

    render() {
        return (
        <Form>
        <Modal
            {...this.props}
            className="map-modal"
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Manage Maps
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formManageMaps">
                    <ListGroup>{this.renderMapListItems()}</ListGroup>
                    <Form.Row>
                        <Button className="map-modal__button" variant="success" onClick={() => this.handleAdd()}>+</Button>
                        <Button className="map-modal__button" variant="success" onClick={() => this.handleRemove()}>-</Button>
                        <Button className="map-modal__button" variant="success" onClick={() => this.handleUp()}>Up</Button>
                        <Button className="map-modal__button" variant="success" onClick={() => this.handleDown()}>Down</Button>
                        <Button className="map-modal__button" variant="success" onClick={() => this.handleCopy()}>Copy</Button>
                        <Button className="map-modal__button" variant="success" onClick={() => this.handlePaste()}>Paste</Button>
                    </Form.Row>                    
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onHide}>
                Close
            </Button>
            <Button variant="primary" onClick={() => this.props.onAccept(this.state)} type="submit">
                OK
            </Button>
            </Modal.Footer>
        </Modal>
        </Form>
    );
  }
}

export default MapModal;