import React from 'react';
import {withRouter} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form'
import FileSelector from './FileSelector';
import { Link } from 'react-router-dom';

function MainNavbar(props) {    
  
  function renderMapOptions() {
    let options = [];
    for(let i = 0; i < props.mapNames.length; i++){
      options.push(<option key={"navbar-option" + i} value={i}>{props.mapNames[i]}</option>);
    }
    return options;
  }

  function renderGroupOptions() {
    let options = [];
    let val, txt;
    options.push(<option key={"navbar-option" + i} value={ -1 }>All</option>);
    for(let i = 0; i < arrayLabels.length; i++) {
      val = arrayLabels[i].value;
      txt = arrayLabels[i].text;
      options.push(<option key={"navbar-option" + i} value={ val }>{ txt }</option>);
    }
    return options;
  }

  function renderDropDown() {
    //console.log("Program state:", props.location.pathname);
    let dropdown = [];
    if(props.location.pathname === "/map" || props.location.pathname === "/play"){
      dropdown.push(
        <div className="dropdown-group">                
          <Form.Label className="map-select--label">Map</Form.Label>
          <Form.Control  name="mapSelected" onChange={props.onChangeMap} className="map-select--control" as="select">
            {renderMapOptions()}
          </Form.Control>
          <Button className="managemaps-button" variant="secondary" onClick={props.onShowManageMaps}>...</Button>               
        </div>
      );
    } else if (props.location.pathname === "/") {
      dropdown.push(
        <div className="dropdown-group">                
          <Form.Label className="map-select--label">Group</Form.Label>
          <Form.Control  name="ruleGroupSelected" onChange={props.onFocusRuleGroup} className="map-select--control" as="select">
            {renderGroupOptions()}
          </Form.Control>
        </div>
      );
    }
    return dropdown;
  }

  let arrayLabels = [];  
  for(var i in props.rules) {
    if(typeof props.rules[i] === "string") {
      arrayLabels.push({"text" : props.rules[i], "value" : i});
    }
  }

  return (          
          <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
            <Navbar.Brand href="#home">Cellulr</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav>
              <NavDropdown title="File" onToggle={props.onToggleMenu}>
                <NavDropdown.Item onSelect={props.onNew}>New</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onSelect={props.onExportRules}>Save...</NavDropdown.Item>
                <NavDropdown.Item><FileSelector label="Open..." onFileLoaded={(e) => props.onFileLoaded(e)} /></NavDropdown.Item>
                <NavDropdown.Item><FileSelector label="Import group..." onFileLoaded={(e) => props.onGroupLoaded(e)} /></NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Sprites" onToggle={props.onToggleMenu}>                
                <NavDropdown.Item label="Settings" onSelect={props.onSpriteSettings} as={Link} to="/settings">Settings...</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onSelect={props.onSelect} eventKey="shapes.png">Shapes</NavDropdown.Item>
                <NavDropdown.Item onSelect={props.onSelect} eventKey="ninja.png">Ninja</NavDropdown.Item>
                <NavDropdown.Item onSelect={props.onSelect} eventKey="owls.png">Owls and Butterflies</NavDropdown.Item>
                <NavDropdown.Item onSelect={props.onSelect} eventKey="mushrooms.png">Mushrooms</NavDropdown.Item>
                <NavDropdown.Item onSelect={props.onSelect} eventKey="caterpillar.png">Caterpillar</NavDropdown.Item>
                <NavDropdown.Item onSelect={props.onSelect} eventKey="mines.png">Mines</NavDropdown.Item>
                <NavDropdown.Item onSelect={props.onSelect} eventKey="maze.png">Maze</NavDropdown.Item>
                <NavDropdown.Item onSelect={props.onSelect} eventKey="snake.png">Snake</NavDropdown.Item>                
                <NavDropdown.Item onSelect={props.onSelect} eventKey="zzt.png">ZZT</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Examples" onSelect={props.onLoadExample} onToggle={props.onToggleMenu}>                
              <NavDropdown.Item eventKey="brownian-motion">Brownian motion</NavDropdown.Item>
                <NavDropdown.Item eventKey="block-pushing">Block pushing</NavDropdown.Item>                
                <NavDropdown.Item eventKey="path-finder">Path finder</NavDropdown.Item>    
                <NavDropdown.Item eventKey="maze">Maze generator</NavDropdown.Item>            
                <NavDropdown.Item eventKey="rule22">Cellular Automata: Rule 22</NavDropdown.Item>
                <NavDropdown.Item eventKey="ninja-game">Ninja game</NavDropdown.Item>
                <NavDropdown.Item eventKey="snake-game">Snake game</NavDropdown.Item>                
              </NavDropdown>              
              </Nav>
              <Nav className="main-links">
                <ButtonGroup>
                  <Button className="nav-button" variant="danger" as={Link} to="/">Rules</Button>
                  <Button className="nav-button" variant="info" as={Link} to="/map">Map</Button>
                  <Button className="nav-button" variant="secondary" as={Link} to="/settings">Settings</Button>
                  <Button className="nav-button" variant="success" as={Link} to="/play">Play</Button>
                </ButtonGroup>     
                {renderDropDown()}                 
              </Nav>
            </Navbar.Collapse>
          </Navbar>
 );
}


export default withRouter(MainNavbar);