import React from 'react';

function buildFileSelector(){
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('id', 'image-files');
  return fileSelector;
}

class FileSelector extends React.Component {
  componentDidMount() { 
    this.fileSelector = buildFileSelector();
    this.fileSelector.addEventListener('change', this.handleFileSelected, false);
  }
  handleFileSelected = (evt) => {    
    var file = evt.target.files[0]; 
    if(file){
      var reader = new FileReader();        
      reader.soundIndex = this.props.soundIndex;
      reader.handler = this.props.onFileLoaded
      reader.onload = (function(f) {
          return function(e) {
              this.handler(reader.result, file.name, this.soundIndex);
          };
      })(file);      
      //console.log("file.type", file.type);
      reader.readAsDataURL(file);
    }
  }

  handleFileSelect = (e) => {
    e.preventDefault();
    this.fileSelector.click();
  }
  //className="button" href=""
  render(){
    return <span onClick={this.handleFileSelect}>{this.props.children}</span>
  }
}

export default FileSelector



