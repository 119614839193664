import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup'
import Form from 'react-bootstrap/Form';
import ImageFileSelector from './ImageFileSelector';
import cloneDeep from 'lodash/cloneDeep';
import SpritePalette from './SpritePalette';
import Cell from "./Cell.js";
//import { initial } from 'lodash';

class SettingsModal extends React.Component {

    constructor(...args) {
        super(...args);
        if(!this.props.stageWide) return;
        this.state = { 
            stageWide: this.props.stageWide,
            stageHigh: this.props.stageHigh,
            cellswide: this.props.cellswide,
            cellshigh: this.props.cellshigh,
            cellwidth: this.props.cellwidth,
            cellheight: this.props.cellheight,
            persistent: (this.props.persistent === "true"),
            worldWrap: (this.props.worldWrap === "true"),
            rulespritesonmap : (this.props.rulespritesonmap ==="true"),
            timer1: this.props.timer1,
            timer2: this.props.timer2,
            bordercell: this.props.bordercell,
            audio: cloneDeep(this.props.audio),
            audiolabels: this.props.audiolabels.slice(),
            soundtrack: cloneDeep(this.props.soundtrack),
            soundtracklabel: this.props.soundtracklabel,
            randomLow: this.props.randomLow.slice(),
            randomHigh: this.props.randomHigh.slice(),
            randomUnique: this.props.randomUnique.slice(),
            triggerRepeatA: this.props.triggerRepeatA,
            triggerRepeatB: this.props.triggerRepeatB,
            triggerRepeatC: this.props.triggerRepeatC,
            triggerRepeatD: this.props.triggerRepeatD,
            active: 0,        
            resizinganchor: this.props.resizinganchor,
            stageanchor: this.props.stageanchor,
            imgsrc: this.props.imgsrc,
            rulesImgSrc: this.props.rulesImgSrc,
            spriteWidth: this.props.spriteWidth,
            spriteHeight: this.props.spriteHeight,
            spriteSheetWidth: this.props.spriteSheetWidth,
            spriteSheetHeight: this.props.spriteSheetHeight,
            spritesPerRow: this.props.spritesPerRow,
            totalSprites: this.props.totalSprites,
            edit: false,
        }; 
    }
    // Save settings on unmount
    componentWillUnmount() {
        this.props.onHide(this.state)
    }
    
    componentWillReceiveProps(props) {   
        //console.log("componentsWilReceiveProps", props);
        if(!props.stageWide) return;        
        this.setState({ 
            stageWide: props.stageWide,
            stageHigh: props.stageHigh,
            cellswide: props.cellswide,
            cellshigh: props.cellshigh,
            cellwidth: props.cellwidth,
            cellheight: props.cellheight,
            persistent: (props.persistent === "true"),
            worldWrap: (props.worldWrap === "true"),
            rulespritesonmap : (props.rulespritesonmap === "true"),
            timer1: props.timer1,
            timer2: props.timer2,
            bordercell: props.bordercell,
            audio: cloneDeep(props.audio),
            audiolabels: props.audiolabels.slice(),
            soundtrack: cloneDeep(props.soundtrack),
            soundtracklabel: props.soundtracklabel,            
            randomLow: props.randomLow.slice(),
            randomHigh: props.randomHigh.slice(),
            randomUnique: props.randomUnique.slice(),            
            triggerRepeatA: props.triggerRepeatA,
            triggerRepeatB: props.triggerRepeatB,
            triggerRepeatC: props.triggerRepeatC,
            triggerRepeatD: props.triggerRepeatD,
            resizinganchor: props.resizinganchor,
            stageanchor: props.stageanchor,
            imgsrc: props.imgsrc,
            rulesImgSrc: props.rulesImgSrc,
            spriteWidth: props.spriteWidth,
            spriteHeight:props.spriteHeight,
            spriteSheetWidth: props.spriteSheetWidth,
            spriteSheetHeight: props.spriteSheetHeight,
            spritesPerRow: props.spritesPerRow,
            totalSprites: props.totalSprites,
            active: 0,        
            edit: false
        });         
    }

    handleChange = e => {
        //this.props.handleUpdateSettings([e.target.name], e.target.value);        
        this.setState({      
            [e.target.name]: Number(e.target.value)
        });
    }

    handleRandomLowChange = e => {
        let randomLow = this.state.randomLow.slice();
        randomLow[e.target.dataset.randomindex] = e.target.value;                
        this.setState({      
            randomLow: randomLow.slice()
        });
    }

    handleRandomHighChange = e => {
        let randomHigh = this.state.randomHigh.slice();
        randomHigh[e.target.dataset.randomindex] = e.target.value;                
        this.setState({      
            randomHigh: randomHigh.slice()
        });
    }

    handleRandomUniqueChange = e => {
        let randomUnique = this.state.randomUnique.slice();                
        randomUnique[e.target.dataset.randomindex] = e.target.checked;
        
        this.setState({      
            randomUnique: randomUnique.slice()
        });
    }

    handleSpriteSizeChange = e => {
        let spriteWidth = this.state.spriteWidth;
        let spriteHeight = this.state.spriteHeight;
        if(e.target.name === "spriteWidth"){
            spriteWidth = Number(e.target.value);
        } else if(e.target.name === "spriteHeight"){
            spriteHeight = Number(e.target.value);
        }

        let spritesPerRow = this.state.spritesPerRow;
        let totalSprites = this.state.totalSprites;
        if(spriteWidth >= 8 && spriteWidth <= 512 && spriteHeight >= 8 && spriteHeight <= 512){
            spritesPerRow = Number(this.state.spriteSheetWidth) / Number(spriteWidth);
            totalSprites = Math.floor(spritesPerRow * (Math.floor(Number(this.state.spriteSheetHeight) / Number(spriteHeight))));
        }
        this.setState({            
            spriteWidth: Number(spriteWidth),
            spriteHeight: Number(spriteHeight),
            spritesPerRow: Number(spritesPerRow),
            totalSprites: Number(totalSprites)
          });
    }

    handleStringChange = e => {
        this.setState({      
            [e.target.name]: String(e.target.value)
          });
    }


    handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            this.setState({ 
                edit: false
            });
        }
    }
    handleBlur = (e) => {
        this.setState({ 
            edit: false
        });
    }

    handleSoundNameEdit = e => {        
        var newAudioLabels = this.state.audiolabels.slice();
        newAudioLabels[this.state.active] = e.target.value;
        this.setState({      
            audiolabels: newAudioLabels.slice()
        });     
    }

    listItemClicked(i){
        let edit = false;
        if(this.state.active === Number(i)) {
            edit = true;
        }
        this.setState({ 
            edit: edit,
            active: Number(i)
        });
    }

    handleCheck = e => {
        this.setState({      
            [e.target.name]: e.target.checked
          });
    }
    
    handleSoundLoaded(e, filename, i) {     
        let newSound = new Audio();
        let newAudio = cloneDeep(this.state.audio);        
        let newAudioLabels = this.state.audiolabels.slice();
        newSound.addEventListener('loadeddata', () => {                        
            let duration = Math.round(newSound.duration * Math.pow(10, 1)) / Math.pow(10, 1);                                    
            newAudioLabels[i] = filename + " (" + duration + "s)"            
            newAudio[i] = e;
            this.setState({
                audio: newAudio,
                audiolabels: newAudioLabels.slice()
            });
          })
          newSound.src = e;
    }

    handleSoundtrackLoaded(e, filename) {
        let newSoundtrack = new Audio();
        newSoundtrack.addEventListener('loadeddata', () => {
            let duration = Math.round(newSoundtrack.duration * Math.pow(10, 1)) / Math.pow(10, 1);
            let soundtrackLabel = filename + " (" + duration + "s)";
            this.setState({
                soundtrack: e,
                soundtracklabel: soundtrackLabel
            });
          })
          newSoundtrack.src = e;
    }

    handleAddSound = e => {
        let newAudio = cloneDeep(this.state.audio);
        newAudio.splice(this.state.active + 1, 0, new Audio());
        
        let newAudiolabels = this.state.audiolabels.slice();
        newAudiolabels.splice(this.state.active + 1, 0, "Empty");

        this.setState({
            audio: newAudio,
            audiolabels: newAudiolabels,
            active: Number(this.state.active + 1)
        });
    }

    handleRemoveSound = e => {
        let newAudio = cloneDeep(this.state.audio.slice());
        newAudio.splice(this.state.active, 1);

        let newAudiolabels = this.state.audiolabels.slice();
        newAudiolabels.splice(this.state.active, 1);

        this.setState({
            audio: newAudio,
            audiolabels: newAudiolabels
        });
    }

    playSound(i) {      
        let playSound = new Audio(this.state.audio[i]);
        playSound.addEventListener('loadeddata', () => {                        
            playSound.play();
        });      
    }

    playSoundtrack() {
        let playSound = new Audio(this.state.soundtrack);
        playSound.addEventListener('loadeddata', () => {
            playSound.play();
        });
    }

    spriteSheetLoaded = e => {
        let img = new Image()
        img.scope = this;
        img.onload = function(e){
            let spritesPerRow = Number(this.width) / Number(this.scope.state.spriteWidth);
            let totalSprites = Math.floor(spritesPerRow * (Math.floor(Number(this.height) / Number(this.scope.state.spriteHeight))));

            this.scope.setState({
                imgsrc: this.src,
                spriteSheetWidth: this.width,
                spriteSheetHeight: this.height,
                spritesPerRow: spritesPerRow,
                totalSprites: totalSprites,
            });
        }
        img.src = e;
    }

    onRulesSpriteSheetLoaded = e => {
        this.setState({rulesImgSrc:e});
    }

    render() {
        if(!this.props.stageWide) return null;
        // Audio elements
        let audioUIElements = [];

        // SFX
        for(let i = 0; i < this.state.audio.length; i++){
            if(Number(this.state.active) === Number(i)) {
                if(this.state.edit) {
                    // If Editing 
                    audioUIElements.push(
                        <Container>
                            <Row>     
                                <ListGroup.Item
                                    key={'SoundItemEditing' + i} 
                                    action 
                                    active 
                                    className="col-9"
                                    onClick={() => this.listItemClicked(i)}
                                >
                                <Container>
                                    <Row>                                                                 
                                        <div className="col-6">                            
                                            <ImageFileSelector soundIndex={i} label={"Sound " + (i + 1) + "..." } onFileLoaded={(e, filename, i) => this.handleSoundLoaded(e, filename, i)}>
                                                <Button variant="info">{"Sound " + (i + 1) + "..." }</Button>
                                            </ImageFileSelector>
                                        </div>                            
                                        <div className="col-6">                                                        
                                            <div className="settings-modal__label">
                                            <Form.Control 
                                                autoFocus
                                                name={"sound-label" + i}
                                                editing = {i}
                                                type="text" 
                                                placeholder={this.state.audiolabels[i]}
                                                onChange={this.handleSoundNameEdit}
                                                onKeyPress={this.handleKeyPress}
                                                onBlur={this.handleBlur}
                                            />
                                            </div>
                                        </div>                                                             
                                    </Row>
                                </Container>         
                                </ListGroup.Item>                       
                                <div className="col-3">
                                    <Button variant="secondary" className="settings-modal__sound-play-button" onClick={() => this.playSound(i)}>Play</Button>
                                </div>
                            </Row>
                        </Container>);       
                } else {
                    // If Selected but not editing
                    audioUIElements.push(
                        <Container>
                            <Row>     
                                <ListGroup.Item
                                    key={'SoundItem' + i} 
                                    action 
                                    active 
                                    className="col-9"
                                    onClick={() => this.listItemClicked(i)}>
                                        <Container>
                                            <Row>                                                                 
                                                <div className="col-6">                            
                                                    <ImageFileSelector soundIndex={i} label={"Sound " + (i + 1) + "..." } onFileLoaded={(e, filename, i) => this.handleSoundLoaded(e, filename, i)}>
                                                            <Button variant="info">{"Sound " + (i + 1) + "..." }</Button>
                                                    </ImageFileSelector>
                                                </div>                            
                                                <div className="col-6">                                                        
                                                    <div className="settings-modal__label">
                                                        {this.state.audiolabels[i]}
                                                    </div>   
                                                </div>
                                            </Row>
                                        </Container>
                                </ListGroup.Item>
                            <div className="col-3">
                                <Button variant="secondary" className="settings-modal__sound-play-button" onClick={() => this.playSound(i)}>Play</Button>
                            </div>
                        </Row>
                    </Container>);
                }                
            } else {
                // If not selected
                audioUIElements.push(    
                    <Container>
                        <Row>                                             
                            <ListGroup.Item
                                key={'SoundItem' + i} 
                                onClick={() => this.listItemClicked(i)}
                                className="col-9"
                            >
                                <Container>
                                    <Row>
                                        <div className="col-6">                            
                                            <ImageFileSelector soundIndex={i} label={"Sound " + (i + 1) + "..." } onFileLoaded={(e, filename, i) => this.handleSoundLoaded(e, filename, i)}><Button>{"Sound " + (i + 1) + "..." }</Button></ImageFileSelector>
                                        </div>                            
                                        <div className="col-6">                                                        
                                            <div className="settings-modal__label">
                                                {this.state.audiolabels[i]}
                                            </div>
                                        </div>                                                              
                                    </Row>
                                </Container>
                            </ListGroup.Item>
                            <div className="col-3">
                                <Button variant="secondary" className="settings-modal__sound-play-button" onClick={() => this.playSound(i)}>Play</Button>
                            </div>
                        </Row>
                    </Container>);
            } 
        }
        //  Soundtrack
        audioUIElements.push(
            <Container>
                <Row>
                    <ListGroup.Item
                        key={'soundtrack'}
                        className="col-9"
                    >
                        <Container>
                            <Row>
                                 <div className="col-6">
                                     <ImageFileSelector label={"Soundtrack..." } onFileLoaded={(e, filename) => this.handleSoundtrackLoaded(e, filename)}>
                                         <Button variant="info">{"Soundtrack..."}</Button>
                                     </ImageFileSelector>
                                </div>
                                <div className="col-6">
                                    <div className="settings-modal__label">
                                        {this.state.soundtracklabel}
                                    </div>
                                 </div>
                            </Row>
                        </Container>
                    </ListGroup.Item>
                    <div className="col-3">
                        <Button variant="secondary" className="settings-modal__sound-play-button" onClick={() => this.playSoundtrack()}>Play</Button>
                    </div>
                </Row>
            </Container>
        );

        // Random Triggers
        let randomTriggers = [];
        for(let i = 1; i < 12; i++){
            randomTriggers.push(
                <ListGroup.Item>
                    <Row>
                        <div className="col-3">                                                        
                            <div className="settings-modal__small-label">{"Random " + (i + 1)}</div>
                        </div>                      
                        <div className="col-3">                            
                            <Form.Control
                                name={"randomLow"}
                                data-randomindex={i}
                                value={this.state.randomLow[i]}
                                onChange={this.handleRandomLowChange}
                                type="input"
                                placeholder="1"
                            />
                        </div>                                                        
                        <div className="col-3">
                            <Form.Control
                                name={"randomHigh"}
                                data-randomindex={i}
                                value={this.state.randomHigh[i]}
                                onChange={this.handleRandomHighChange}
                                type="input"
                                placeholder="100"
                            />
                        </div>
                        <div className="col-3">
                            <Form.Check 
                                    name={"randomUnique"}                    
                                    data-randomindex={i}
                                    type="checkbox"  
                                    label="Unique"
                                    checked={this.state.randomUnique[i]} 
                                    onChange={this.handleRandomUniqueChange}                                   
                                />
                        </div>
                    </Row>
                </ListGroup.Item>
            );
        }

        // Triggers Conditional
        let triggersAdditional = [];
        let letters = ["A", "B", "C", "D"];
        //let repeats = [this.state.triggerRepeatA, this.state.triggerRepeatB, this.state.triggerRepeatC, this.state.triggerRepeatD];
        let setting = ["triggerRepeatA", "triggerRepeatB", "triggerRepeatC", "triggerRepeatD"];
        for(let i = 0; i < 4; i++){
            triggersAdditional.push(
                <ListGroup.Item>
                    <Row>
                        <div className="col-3">                                                        
                            <div className="settings-modal__small-label">{"Repeat " + letters[i]}</div>
                        </div>                      
                        <div className="col-3">                            
                            <Form.Control
                                name={setting[i]}
                                value={this.state[setting[i]]}
                                onChange={this.handleChange}
                                type="input"
                                placeholder={this.state[setting[i]]}
                            />
                        </div>                                                                                
                    </Row>
                </ListGroup.Item>
            );
        }
        
        let settingsWidthClass; 
        if(this.props.totalSprites >= 128){
            settingsWidthClass = "settings-modal--narrow"
        } else {
            settingsWidthClass = "";
        }       
        
        return (
        <div>
            <SpritePalette 
                active={this.props.spriteIndex}
                imgsrc = {this.state.imgsrc}
                className="sprite-palette bg-dark"
                spriteWidth={this.state.spriteWidth}
                spriteHeight={this.state.spriteHeight}
                spritesPerRow={this.state.spritesPerRow}
                totalSprites={this.state.totalSprites}
                onClick={this.props.onSpriteSelect}
            />
            <Form className={"settings-modal " + settingsWidthClass}>
                <Form.Row className="form-border bottom-margin">
                    <h1>Settings</h1>
                </Form.Row>
                <Form.Group controlId="formBasicEmail">
                <Tabs id="settings-tabs" className="mb-3" activeKey={this.props.activeTab} onSelect={this.props.onTabSelected}>
                    <Tab eventKey="Map" title="Map" >
                    <Form.Row>
                        <div className="col-3">
                            <Form.Label>Stage Wide</Form.Label>
                            <Form.Control
                                name="stageWide"
                                value={this.state.stageWide}
                                onChange={this.handleChange}
                                type="input"
                                placeholder="Not Set"
                            />
                        </div>
                        <div className="col-3">
                            <Form.Label>Stage High</Form.Label>
                            <Form.Control
                                name="stageHigh"
                                value={this.state.stageHigh}
                                onChange={this.handleChange}
                                type="input"
                                placeholder="Not Set"
                            />
                        </div>
                    </Form.Row>
                    <Form.Row>
                        <div className="col-3">
                            <Form.Label>Cells Wide</Form.Label>
                            <Form.Control
                                name="cellswide"
                                value={this.state.cellswide}
                                onChange={this.handleChange}
                                type="input"
                                placeholder="Not Set"
                            />
                        </div>
                        <div className="col-3">
                            <Form.Label>Cells High</Form.Label>
                            <Form.Control
                                name="cellshigh"
                                value={this.state.cellshigh}
                                onChange={this.handleChange}
                                type="input"
                                placeholder="Not Set"
                            />
                        </div>
                    </Form.Row>
                    <Form.Row>
                        <div className="col-3">
                            <Form.Label>Cell Width</Form.Label>
                            <Form.Control
                                name="cellwidth"
                                value={this.state.cellwidth}
                                onChange={this.handleChange}
                                type="input"
                                placeholder="Not Set"
                            />
                        </div>
                        <div className="col-3">
                            <Form.Label>Cells Height</Form.Label>
                            <Form.Control
                                name="cellheight"
                                value={this.state.cellheight}
                                onChange={this.handleChange}
                                type="input"
                                placeholder="Not Set"
                            />
                        </div>
                        </Form.Row>
                        <Form.Row  className="form-border">
                            <div className="col-3 bottom-margin">
                                <Form.Label>Resizing Anchor</Form.Label>
                                <Form.Control aria-label="Resizing anchor"
                                    as="select"
                                    name="resizinganchor"
                                    value={this.state.resizinganchor}
                                    onChange={this.handleStringChange}
                                >
                                    <option value="NW">Top left</option>
                                    <option value="N">Top centre</option>
                                    <option value="NE">Top right</option>
                                    <option value="W">Centre left</option>
                                    <option value="C">Centre</option>
                                    <option value="E">Centre right</option>
                                    <option value="SW">Bottom left</option>
                                    <option value="S">Bottom centre</option>
                                    <option value="SE">Bottom right</option>
                                </Form.Control>
                            </div>
                            <div className="col-3 bottom-margin">
                                <Form.Label>Stage Alignment</Form.Label>
                                <Form.Control aria-label="Stage alignment"
                                    as="select"
                                    name="stageanchor"
                                    value={this.state.stageanchor}
                                    onChange={this.handleStringChange}
                            >                                        
                                    <option value="NW">Top left</option>
                                    <option value="N">Top centre</option>
                                    <option value="NE">Top right</option>
                                    <option value="W">Centre left</option>
                                    <option value="C">Centre</option>
                                    <option value="E">Centre right</option>
                                    <option value="SW">Bottom left</option>
                                    <option value="S">Bottom centre</option>
                                    <option value="SE">Bottom right</option>
                                </Form.Control>
                            </div>
                            </Form.Row>
                        <Form.Row>
                            <div className="col-3">
                                <Form.Label>Border Cell</Form.Label>
                                <Cell
                                    value = {this.props.bordercell}                                        
                                    spriteSheet = {this.props.spriteSheet} 
                                    spriteWidth = {this.props.spriteWidth}
                                    spriteHeight = {this.props.spriteHeight} 
                                    spritesPerRow = {this.props.spritesPerRow}
                                    imgsrc = {this.state.imgsrc}
                                    transform = {"scale(" + (32 / Math.max(this.props.spriteWidth, this.props.spriteHeight)) + ")"}
                                    renderStyle = "crisp-edges"
                                    onClick={ () => this.props.onChangeBorderCell() }
                                    position = "relative"
                                />   
                            </div>
                            <div className="col-3">
                                <Form.Check 
                                    name="worldWrap"
                                    type="checkbox"  
                                    label="World Wrap"
                                    defaultChecked={this.state.worldWrap}
                                    onChange={this.handleCheck}                                   
                                />
                                <Form.Check 
                                    name="persistent"
                                    type="checkbox"  
                                    label="Persistent"
                                    defaultChecked={this.state.persistent}
                                    onChange={this.handleCheck}                                   
                                />
                                <Form.Check 
                                    name="rulespritesonmap"                                 
                                    type="checkbox"
                                    label="Use rule sprites"
                                    defaultChecked={this.state.rulespritesonmap}
                                    onChange={this.handleCheck}                                   
                                />
                            </div>
                        </Form.Row>
                        </Tab>
                        <Tab eventKey="Sprites" title="Sprites">
                            <Form.Row className="form-border mt-4">
                                <div className="col-3 bottom-margin">
                                    <ImageFileSelector label="Open..." onFileLoaded={(e) => this.spriteSheetLoaded(e)}><Button>Sprite sheet...</Button></ImageFileSelector>
                                </div>
                                <div className="col-9 bottom-margin">
                                    <img src={this.state.imgsrc} alt="Sprite sheet"></img>
                                </div>
                            </Form.Row>
                          <Form.Row className="form-border">
                              <div className="col-3 bottom-margin">
                                  <ImageFileSelector label="Open..." onFileLoaded={(e) => this.onRulesSpriteSheetLoaded(e)}><Button>Rules sprite sheet...</Button></ImageFileSelector>
                              </div>
                              <div className="col-9 bottom-margin">
                                  <img src={this.state.rulesImgSrc} alt="Sprite sheet"></img>
                              </div>
                          </Form.Row>
                          <Form.Row>
                              <div className="col-3">
                                  <Form.Label>Sprite Width</Form.Label>
                                  <Form.Control
                                      name="spriteWidth"
                                      value={this.state.spriteWidth}
                                      onChange={this.handleSpriteSizeChange}//this.handleSpriteSizeChange
                                      type="input"
                                      placeholder={this.state.spriteWidth}
                                  />
                              </div>
                              <div className="col-3">
                                  <Form.Label>Sprite Height</Form.Label>
                                  <Form.Control
                                      name="spriteHeight"
                                      value={this.state.spriteHeight}
                                      onChange={this.handleSpriteSizeChange}//this.handleSpriteSizeChange
                                      type="input"
                                      placeholder={this.state.spriteHeight}
                                  />
                              </div>
                          </Form.Row>
                        </Tab>
                        <Tab eventKey="Timers" title="Timers">
                            <Form.Row>
                            <div className="col-3">
                                <Form.Label>Timer 1</Form.Label>
                                <Form.Control
                                    name="timer1"
                                    value={this.state.timer1}
                                    onChange={this.handleChange}
                                    type="input"
                                    placeholder="Not Set"
                                />
                            </div>
                            <div className="col-3">
                                <Form.Label>Timer 2</Form.Label>
                                <Form.Control
                                    name="timer2"
                                    value={this.state.timer2}
                                    onChange={this.handleChange}
                                    type="input"
                                    placeholder="Not Set"
                                />
                            </div>
                        </Form.Row>
                    </Tab>
                    <Tab eventKey="Sounds" title="Sounds">
                        <ListGroup>{audioUIElements}</ListGroup>
                        <Form.Row>
                            <Button className="map-modal__button" variant="success" onClick={() => this.handleAddSound()}>+</Button>
                            <Button className="map-modal__button" variant="success" onClick={() => this.handleRemoveSound()}>-</Button>
                        </Form.Row>                    
                    </Tab>
                    <Tab eventKey="random-conditions" title="Conditionals">
                        <ListGroup>{randomTriggers}</ListGroup>
                    </Tab>
                    <Tab eventKey="triggers-additional" title="Additionals">
                        <ListGroup>{triggersAdditional}</ListGroup>
                    </Tab>
                    </Tabs>
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
            </Form>
        </div>
    );
  }
}

export default SettingsModal;