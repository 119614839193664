import React, { useState } from 'react';
import Cell from './Cell';
import SpritePalette from './SpritePalette';

function Map(props) {

    const [isMouseDown, setMouseDown] = useState(0);
    let scale = "scale(" + String(props.cellwidth / props.spriteWidth) + ")";

    function handleMouseDown() {
        setMouseDown(1);
    }

    function handleMouseUp() {
        setMouseDown(0);
    }
    
    function handleMouseOver(i) {
        if(isMouseDown) {
            props.onClick(i);
        }
    }

    function renderMapCell(i, x, y) {
        let spriteCellStyle = {
            float: "left",
            width: props.spriteWidth,
            height: props.spriteHeight,
            transform: scale,
            transformOrigin: "top left",
            position: "absolute",
            left: x * props.cellwidth,
            top: y * props.cellheight,        
        }
        // original transfrom value above: scale(" + 32 / props.spriteWidth + ")"
        return(
            <Cell
                key = {i}
                value = {props.cells[i]}
                style = {spriteCellStyle} 
                imgsrc = {props.imgsrc}
                spriteWidth = {props.spriteWidth} 
                spriteHeight = {props.spriteHeight} 
                spritesPerRow = {props.spritesPerRow}
                position = "absolute"
                renderStyle = "crisp-edges"
                onClick={ () => props.onClick(i) }
                onContextMenu={(e) => props.onRightClick(e, i)} 
                onMouseOver={ () => handleMouseOver(i) }
            />
        );
    }

    const gridWidth = props.cellswide;
    const gridHeight = props.cellshigh;
    let offsetX;
    let offsetY;

    if(props.stageanchor === "NW" || props.stageanchor === "W" || props.stageanchor === "SW") {
        offsetX = 0;
    }
    else if(props.stageanchor === "N" || props.stageanchor === "C" || props.stageanchor === "S") {
        offsetX = Math.floor((gridWidth - props.stageWide) * 0.5) * props.cellwidth;
    }
    else if(props.stageanchor === "NE" || props.stageanchor === "E" || props.stageanchor === "SE") {
        offsetX = (gridWidth - props.stageWide) * props.cellwidth;
    }

    if(props.stageanchor === "NW" || props.stageanchor === "N" || props.stageanchor === "NE") {
        offsetY = 0;
    }
    else if(props.stageanchor === "W" || props.stageanchor === "C" || props.stageanchor === "E") {
        offsetY = Math.floor((gridHeight - props.stageHigh) * 0.5) * props.cellheight;
    }
    else if(props.stageanchor === "SE" || props.stageanchor === "S" || props.stageanchor === "SW") {
        offsetY = (gridHeight - props.stageHigh) * props.cellheight;
    }

    let cells = [];
    let i = 0;
    for (let y = 0; y < gridHeight; y++) {
        for (let x = 0; x < gridWidth; x++) {            
            cells.push(renderMapCell(i, x, y));
            i++;  
        }    
    }

    let stageStyleWidth = props.stageWide * props.cellwidth;
    let stageStyleHeight = props.stageHigh * props.cellheight;

    let borderStyleLeft = {
        borderLeft: "2px solid pink",
        height: stageStyleHeight,
        position: "absolute",
        zIndex: 500,
        top: offsetY,
        left: offsetX
    };
    let borderStyleRight = {
        borderLeft: "2px solid pink",
        height: stageStyleHeight,
        position: "absolute",
        zIndex: 500,
        top: offsetY,
        left: offsetX + stageStyleWidth
    };
    let borderStyleTop = {
        borderTop: "2px solid pink",
        width: stageStyleWidth,
        position: "absolute",
        zIndex: 50,
        left:offsetX,
        top: offsetY
    };
    let borderStyleBottom = {
        borderTop: "2px solid pink",
        width: stageStyleWidth,
        position: "absolute",
        zIndex: 50,
        left: offsetX,
        top: offsetY + stageStyleHeight
    };

    let mapWidth;
    if(props.totalSprites >= 128) {
       mapWidth = "map-wrapper--128";
     } 
     else if(props.totalSprites >= 64) {
       mapWidth = "map-wrapper--64";
     } 
     else if(props.totalSprites >= 32) {
       mapWidth = "map-wrapper--32";
     }
     else if(props.totalSprites >= 0) {
       mapWidth = "map-wrapper--16";
     }
    let mapClasses = "map-wrapper " + mapWidth;
    //bg-dark 
    return (
        <div>
            <SpritePalette 
                active={props.spriteIndex}
                imgsrc = {props.imgsrc}      
                className="sprite-palette bg-dark"          
                spriteWidth={props.spriteWidth} 
                spriteHeight={props.spriteHeight} 
                totalSprites={props.totalSprites} 
                spritesPerRow={props.spritesPerRow} 
                onClick={props.onSpriteSelect} 
            />
            
            <div onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} className={mapClasses} style={{width:stageStyleWidth,minWidth:320}}>
                <div style={borderStyleTop} />
                <div style={borderStyleLeft} />
                {cells}
                <div style={borderStyleRight} />
                <div style={borderStyleBottom} />
            </div>
        </div>
    );
}

export default Map;