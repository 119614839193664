import React from 'react';

function Cell(props) {
    /*
      props.spriteSheet
      props.spriteWidth
      props.spriteHeight
      props.spritesPerRow
      props.outlineStyle
    */
  function preventDragHandler(e) {
    e.preventDefault();
  }
    let spriteInnerStyle = {
        width: props.spriteWidth,
        height: props.spriteHeight,
        backgroundColor: "#ddd",
        outline: props.outlineStyle
    }
    let imgsrc = "./img/blank.png";
    let position = "";
    if(props.value !== 0) {
      imgsrc = props.imgsrc;
      let offsetY = "0";
      if(props.value > props.spritesPerRow){
        offsetY = props.spriteHeight * (Math.floor((props.value - 1)  / props.spritesPerRow)) * -1 + "px"
      }
      position = props.spriteWidth * ((props.value - 1) % props.spritesPerRow) * -1 + "px " + offsetY;
      spriteInnerStyle.objectPosition = position;
      spriteInnerStyle.objectFit = "none";
      spriteInnerStyle.imageRendering = props.renderStyle;      
    }
    spriteInnerStyle.position = props.position;
    //spriteInnerStyle.top = (32 - props.spriteWidth) * 0.5;
    //spriteInnerStyle.left = (32 - props.spriteHeight) * 0.5;

    if(props.transform) {
      spriteInnerStyle.transform = props.transform;
    }
    return (
      <div style={props.style} className={props.className}>   
        <img 
          src={imgsrc} 
          alt="cell" 
          style={spriteInnerStyle} 
          className="cell--button" 
          onClick={props.onClick}
          onContextMenu={props.onContextMenu}
          onMouseOver={props.onMouseOver} 
          onDragStart={preventDragHandler} 
        />  
      </div>
    );
}

export default Cell;